<template>
  <div id="app">

    <el-collapse v-model="activeName" accordion>
      <el-collapse-item title="Rodzaj skóry" name="skinTypes">
        <SkinTypesList @update="updateSelectedSkinTypes" />
      </el-collapse-item>
      <el-collapse-item title="Problemy skórne" name="skinProblems">
        <SkinProblemsList @update="updateSelectedSkinProblems" />
      </el-collapse-item>
      <el-collapse-item title="Część ciała" name="bodyParts">
        <BodyPartsList @update="updateSelectedBodyParts" />
      </el-collapse-item>
      <el-collapse-item title="Składniki" name="ingredients">
        <IngredientsList @update="updateSelectedIngredients" />
      </el-collapse-item>
      <el-collapse-item title="Składniki aktywne" name="activeIngredients">
        <ActiveIngredientsList @update="updateSelectedActiveIngredients" />
      </el-collapse-item>
    </el-collapse>
    <div>
      <h2>Szukane</h2>
      <p>Typ cery: {{ selectedSkinTypes ? selectedSkinTypes.join(', ') : '' }}</p>
      <p>Problemy skórne: {{ selectedSkinProblems ? selectedSkinProblems.join(', ') : '' }}</p>
      <p>Część ciała: {{ selectedBodyParts ? selectedBodyParts.join(', ') : '' }}</p>
      <p>Składniki: {{ selectedIngredients ? selectedIngredients.join(', ') : '' }}</p>
      <p>Składniki czynne: {{ selectedActiveIngredients ? selectedActiveIngredients.join(', ') : '' }}</p>

    </div>

    <FilteredProducts 
    :filteredProducts="filteredProducts"
    :selectedActiveIngredients="selectedActiveIngredients"
    :selectedIngredients="selectedIngredients"
    :selectedSkinProblems="selectedSkinProblems"
    :selectedSkinTypes="selectedSkinTypes"
    :selectedBodyParts="selectedBodyParts"
    />
  </div>
</template>

<script>
import ActiveIngredientsList from './components/ActiveIngredientsList.vue';
import IngredientsList from './components/IngredientsList.vue';
import SkinProblemsList from './components/SkinProblemsList.vue';
import SkinTypesList from './components/SkinTypesList.vue';
import BodyPartsList from './components/BodyPartsList.vue';
import FilteredProducts from './components/FilteredProducts.vue';
import { products } from './components/data/products.js';
import { products_features } from './components/data/products_features.js';
export default {
  name: 'App',
  components: {
    SkinTypesList,
    BodyPartsList,
    SkinProblemsList,
    IngredientsList,
    ActiveIngredientsList,
    FilteredProducts
  },
  data() {
    return {
      activeName: 'skinTypes',
      selectedSkinTypes: [],
      selectedSkinProblems: [],
      selectedBodyParts: [],
      selectedIngredients: [],
      selectedActiveIngredients: [],

      products,
      products_features,
      filteredProducts: []
    };
  },
  methods: {

    
    filterProducts() {
      // Step 1: Merge product data with their features
      const mergedProducts = this.products.products.map(product => {
        const features = this.products_features.products_features.find(f => f.id === product.id);
        return {
          ...product,
          ...features
        };
      });

      console.log(mergedProducts);

      // Step 2: Create product scores based on the selected criteria
      const productScores = mergedProducts.map(product => {
        const matchedSkinTypes = product.skin_types ? product.skin_types.filter(type => this.selectedSkinTypes.includes(type)) : [];
        const matchedActiveIngredients = product.basic_active_ingredients ? product.basic_active_ingredients.filter(ingredient => this.selectedActiveIngredients.includes(ingredient)) : [];
        const matchedIngredients = product.ingredients ? product.ingredients.filter(ingredient => this.selectedIngredients.includes(ingredient)) : [];
        const matchedSkinProblems = product.skin_problems ? product.skin_problems.filter(problem => this.selectedSkinProblems.includes(problem)) : [];
        const matchedBodyParts = product.body_parties ? product.body_parties.filter(part => this.selectedBodyParts.includes(part)) : [];

        return {
          product,
          matchedSkinTypes,
          matchedActiveIngredients,
          matchedIngredients,
          matchedSkinProblems,
          matchedBodyParts
        };
      });

      // Step 3: Sort the products based on the number of matches and other criteria
      productScores.sort((a, b) => {
        const countA = a.matchedSkinTypes.length + a.matchedActiveIngredients.length + a.matchedIngredients.length + a.matchedSkinProblems.length + a.matchedBodyParts.length;
        const countB = b.matchedSkinTypes.length + b.matchedActiveIngredients.length + b.matchedIngredients.length + b.matchedSkinProblems.length + b.matchedBodyParts.length;

        if (countA !== countB) {
          return countB - countA;
        }
        if (a.product.amount !== b.product.amount) {
          return b.product.amount - a.product.amount;
        }
        return b.product.net_price - a.product.net_price;
      });

      // Step 4: Select products that cover all selected criteria
      const selectedProducts = [];
      const coveredCriteria = {
        skinTypes: new Set(),
        activeIngredients: new Set(),
        ingredients: new Set(),
        skinProblems: new Set(),
        bodyParts: new Set()
      };

      for (const { product, matchedSkinTypes, matchedActiveIngredients, matchedIngredients, matchedSkinProblems, matchedBodyParts } of productScores) {
        const newSkinTypes = matchedSkinTypes.filter(type => !coveredCriteria.skinTypes.has(type));
        const newActiveIngredients = matchedActiveIngredients.filter(ingredient => !coveredCriteria.activeIngredients.has(ingredient));
        const newIngredients = matchedIngredients.filter(ingredient => !coveredCriteria.ingredients.has(ingredient));
        const newSkinProblems = matchedSkinProblems.filter(problem => !coveredCriteria.skinProblems.has(problem));
        const newBodyParts = matchedBodyParts.filter(part => !coveredCriteria.bodyParts.has(part));

        if (newSkinTypes.length > 0 || newActiveIngredients.length > 0 || newIngredients.length > 0 || newSkinProblems.length > 0 || newBodyParts.length > 0) {
          selectedProducts.push(product);

          newSkinTypes.forEach(type => coveredCriteria.skinTypes.add(type));
          newActiveIngredients.forEach(ingredient => coveredCriteria.activeIngredients.add(ingredient));
          newIngredients.forEach(ingredient => coveredCriteria.ingredients.add(ingredient));
          newSkinProblems.forEach(problem => coveredCriteria.skinProblems.add(problem));
          newBodyParts.forEach(part => coveredCriteria.bodyParts.add(part));
        }

        if (coveredCriteria.skinTypes.size === this.selectedSkinTypes.length &&
          coveredCriteria.activeIngredients.size === this.selectedActiveIngredients.length &&
          coveredCriteria.ingredients.size === this.selectedIngredients.length &&
          coveredCriteria.skinProblems.size === this.selectedSkinProblems.length &&
          coveredCriteria.bodyParts.size === this.selectedBodyParts.length) {
          break;
        }
      }

      this.filteredProducts = selectedProducts;
    },

    updateSelectedBodyParts(selectedBodyParts) {
      this.selectedBodyParts = selectedBodyParts;
      this.filterProducts();
    },
    updateSelectedActiveIngredients(selectedActiveIngredients) {
      this.selectedActiveIngredients = selectedActiveIngredients;
      this.filterProducts();
    },
    updateSelectedSkinProblems(selectedSkinProblems) {
      this.selectedSkinProblems = selectedSkinProblems;
      this.filterProducts();
    },
    updateSelectedSkinTypes(selectedSkinTypes) {
      this.selectedSkinTypes = selectedSkinTypes;
      this.filterProducts();
    },
    updateSelectedIngredients(selectedIngredients) {
      this.selectedIngredients = selectedIngredients;
      this.filterProducts();
    },
  }
};
</script>
<style>
.item {
  margin-top: 10px;
  margin-right: 40px;
}
</style>