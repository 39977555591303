<template>
  <div>
    <h2>Filtered Products</h2>
    <div v-if="filteredProducts.length">
      <div v-for="product in filteredProducts" :key="product.id" class="product">
        <img :src="product.base_img" alt="product.name" />
        <h3 v-html="product.name"> </h3>
        <p v-html="product.description"></p>
        <p>Amount: {{ product.amount }}</p>
        <p>Net Price: ${{ product.net_price.toFixed(2) }}</p>

        <p>
          Część ciała: 
          <span v-for="(part, index) in product.body_parties" :key="index">
            <span :class="{ highlight: selectedBodyParts.includes(part) }">{{ part }}</span>
            <span v-if="index < product.body_parties.length - 1">, </span>
          </span>
        </p>
        <p>
          Problemy skórne: 
          <span v-for="(problem, index) in product.skin_problems" :key="index">
            <span :class="{ highlight: selectedSkinProblems.includes(problem) }">{{ problem }}</span>
            <span v-if="index < product.skin_problems.length - 1">, </span>
          </span>
        </p>
        <p>
          Typ cery: 
          <span v-for="(type, index) in product.skin_types" :key="index">
            <span :class="{ highlight: selectedSkinTypes.includes(type) }">{{ type }}</span>
            <span v-if="index < product.skin_types.length - 1">, </span>
          </span>
        </p>
        <p>
          Składniki: 
          <span v-for="(ingredient, index) in product.ingredients" :key="index">
            <span :class="{ highlight: selectedIngredients.includes(ingredient) }">{{ ingredient }}</span>
            <span v-if="index < product.ingredients.length - 1">, </span>
          </span>
        </p>
        <p>
          Składniki czynne: 
          <span v-for="(activeIngredient, index) in product.basic_active_ingredients" :key="index">
            <span :class="{ highlight: selectedActiveIngredients.includes(activeIngredient) }">{{ activeIngredient }}</span>
            <span v-if="index < product.basic_active_ingredients.length - 1">, </span>
          </span>
        </p>
      </div>
    </div>
    <p v-else>No products match the selected skin types.</p>
  </div>
</template>

<script>
export default {
  props: ['filteredProducts', 'selectedSkinTypes', 'selectedSkinProblems', 'selectedBodyParts', 'selectedIngredients', 'selectedActiveIngredients']
};
</script>

<style scoped>
.product {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.product img {
  max-width: 100px;
}

.highlight {
  font-weight: bold;
  color: red;
}
</style>
