<template>
  <div class="box-card">
    <el-select @change="updateSelected" v-model="selectedParts" multiple placeholder="Select body parts" style="width: 100%">
      <el-option v-for="(part, index) in bodyParts" :key="index" :label="part" :value="part">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { bodyParts } from './data/bodyParts.js';
export default {
  name: 'BodyPartsList',
  data() {
    return {
      bodyParts: bodyParts,
      selectedParts: []
    }
  },
  methods: {
    updateSelected() {
      this.$emit('update', this.selectedParts);
    }
  }
}
</script>

<style scoped>
.box-card {
  width: 400px;
  margin: 20px;
}
</style>