export const activeIngredients = [
    "Żel Aloesowy",
    "Witaminy",
    "Witamina E",
    "Witamina C",
    "Witamina B",
    "Witamina A",
    "Spf50",
    "Spf30",
    "Spf20",
    "Spf15",
    "Spf",
    "Skwalan",
    "Retinol",
    "Resplanta",
    "Probiotyki",
    "Peptydy Biomimetyczne",
    "Peptydy",
    "Peptyd Miedziowy",
    "Olej Bakuchi",
    "Niacynamid",
    "Mocznik",
    "Masło Shea",
    "Kwasy Bha",
    "Kwasy Aha",
    "Kwas Winowy",
    "Kwas Salicylowy",
    "Kwas Pirogronowy",
    "Kwas Mlekowy",
    "Kwas Laktobionowy",
    "Kwas Kawowy",
    "Kwas Hialuronowy",
    "Kwas Glikolowy",
    "Kwas Fitowy",
    "Kwas Ferulowy",
    "Kwas Askorbinowy",
    "Komórki Macierzyste",
    "Kolagen Roślinny",
    "Kolagen",
    "Kofeina",
    "Koenzym Q10",
    "Karoten",
    "Hydrolat",
    "Guarana",
    "Fitokolagen",
    "Filtry Uv",
    "Elastyna",
    "D Panthenol",
    "D Pantenol",
    "Ceramidy",
    "Bakuchiol",
    "Antyoksydanty",
    "Aloes",
    "Adapdogen"
];

activeIngredients.sort((a, b) => a.localeCompare(b, 'pl', { sensitivity: 'base' }));


  