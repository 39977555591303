export const skinTypes = [

    "Zniszczona",
    "Zmęczona",
    "Ziemista",
    "Z Przebarwieniami",
    "Wrażliwa",
    "Trądzikowa",
    "Tłusta",
    "Szara",
    "Sucha",
    "Przesuszona",
    "Odwodniona",
    "Normalna",
    "Naczynkowa",
    "Mieszana",
    "Łuszcząca",
    "Łojotokowa",
    "Dojrzała"
];
skinTypes.sort((a, b) => a.localeCompare(b, 'pl', { sensitivity: 'base' }));