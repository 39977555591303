export const skinProblems = [

    "Problem Odwodnienie",
    "Atopowe Zapalenie Skóry",
    "Blizny Potrądzikowe",
    "Cellulit",
    "Cera Dojrzała",
    "Cera Mieszana",
    "Cera Sucha Odwodniona",
    "Cera Trądzikowa",
    "Cera Tłusta",
    "Cera Wrażliwa",
    "Cera Wrażliwa Naczynkowa",
    "Cera Z Trądzikiem",
    "Cera Ziemista Zmęczona",
    "Cienie Obrzęki Pod Oczami",
    "Egzema",
    "Fotostarzenie",
    "Nadmierny Łojotok",
    "Podrażnienia",
    "Problem Odwodnienie",
    "Problem Przebarwienia",
    "Problem Rozszerzone Pory",
    "Problem Swędzenie Skóry",
    "Problem Trądzik",
    "Problem Zmarszczki",
    "Problemy Zmarszczkie",
    "Przebarwienia",
    "Przesuszenie",
    "Rozszerzone Pory",
    "Stany zapalne",
    "Trądzik Pospolity",
    "Trądzik Różowaty",
    "Utrata Jędrności",
    "Zmarszczki",
    "Łuszczyca"
];
skinProblems.sort((a, b) => a.localeCompare(b, "pl", { sensitivity: "base" }));