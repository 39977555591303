export const  questions = {
    "questions": [
        {
            "question": "Jak Twoja skóra reaguje na zewnętrzne czynniki, takie jak wiatr, mróz, czy słońce?",
            "answers": [
                {"answer": "Skóra staje się czerwona i podrażniona", "skin_type": "Wrażliwa"},
                {"answer": "Skóra jest sucha i ściągnięta", "skin_type": "Sucha"},
                {"answer": "Skóra szybko się łuszczy", "skin_type": "Łuszcząca"},
                {"answer": "Nie zauważam znaczących zmian", "skin_type": "Normalna"}
            ]
        },
        {
            "question": "Jakie są twoje główne obawy dotyczące wyglądu twojej skóry?",
            "answers": [
                {"answer": "Nadmierna produkcja sebum i błyszczenie", "skin_type": "Tłusta"},
                {"answer": "Częste trądzikowe wypryski", "skin_type": "Trądzikowa"},
                {"answer": "Rozszerzone naczynka i rumień", "skin_type": "Naczynkowa"},
                {"answer": "Przebarwienia i nierówny koloryt skóry", "skin_type": "Z Przebarwieniami"},
                {"answer": "Zmęczone, pozbawione blasku oblicze", "skin_type": "Zmęczona"},
                {"answer": "Matowy, ziemisty wygląd", "skin_type": "Ziemista"},
                {"answer": "Widoczne oznaki starzenia", "skin_type": "Dojrzała"}
            ]
        },
        {
            "question": "Jakie problemy pojawiają się na Twojej skórze najczęściej?",
            "answers": [
                {"answer": "Świecąca się strefa T, suche policzki", "skin_type": "Mieszana"},                
                {"answer": "Łuszczenie się i suchość", "skin_type": "Przesuszona"},
                {"answer": "Czynniki zewnętrzne powodują zniszczenia", "skin_type": "Zniszczona"},
                {"answer": "Skóra wydaje się szara i pozbawiona życia", "skin_type": "Szara"},
                {"answer": "Brak elastyczności, uczucie suchości", "skin_type": "Odwodniona"},
                {"answer": "Nadmierna produkcja sebum", "skin_type": "Łojotokowa"}
            ]
        },
        
    ]
}
;
