<template>
  <div class="box-card">
    <el-select @change="updateSelected" v-model="selectedSkinProblems" multiple placeholder="Select ingredients"
      style="width: 100%">
      <el-option v-for="(ingredient, index) in skinProblems" :key="index" :label="ingredient" :value="ingredient">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { skinProblems } from './data/skinProblems.js';
export default {
  name: 'SkinProblemsList',
  data() {
    return {
      skinProblems: skinProblems,
      selectedSkinProblems: []
    }
  },
  methods: {
    updateSelected() {
      this.$emit('update', this.selectedSkinProblems);
    }
  }
}
</script>

<style scoped>
.box-card {
  width: 400px;
  margin: 20px;
}
</style>