<template>
  <div class="box-card">
    <el-select @change="updatedSelected" v-model="selectedSkinTypes" multiple placeholder="Select skin types"
      style="width: 100%">
      <el-option v-for="(type, index) in skinTypes" :key="index" :label="type" :value="type">
      </el-option>
    </el-select>
    <i v-show="!showQuestions">Nie znasz swojego typu cery? <strong><a href="#"
          @click="showQuestions = true">Odpowiedź</a></strong>
      na kilka pytań a dopasujemy je do
      Ciebie</i>
    <a v-show="showQuestions" href="#" @click="showQuestions = false"><strong>Zamknij</strong></a>
    <div v-show="showQuestions" v-for="(question, qIndex) in questions.questions" :key="qIndex" class="question">
      <label>{{ question.question }}</label>
      <div class="answers">
        <div v-for="(answer, aIndex) in question.answers" :key="aIndex">
          <label><input type="checkbox" :name="'question_' + qIndex" :value="answer.skin_type" @change="updatedSelected"
              v-model="selectedSkinTypes" />{{ answer.answer }}</label>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { skinTypes } from './data/skinTypes.js';
import { questions } from './data/questions.js';
export default {
  name: 'SkinTypesList',
  data() {
    return {
      showQuestions: false,
      skinTypes: skinTypes,
      questions: questions,
      selectedSkinTypes: []
    }
  }, methods: {
    updatedSelected() {
      this.$emit('update', this.selectedSkinTypes);
    }

  }
}
</script>

<style scoped>
.box-card {
  width: 400px;
  margin: 20px;
}
</style>