export const bodyParts = [
    "Włosy",
    "Twarz",
    "Oczy",
    "Usta",
    "Zęby",
    "Szyja/Dekolt",
    "Stopy",
    "Dłonie",
    "Ciało",
    "Miejsca intymne"

];

bodyParts.sort((a, b) => a.localeCompare(b, 'pl', { sensitivity: 'base' }));