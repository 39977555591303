export const ingredients = [


    "Woda Różana",
    "Tensup",
    "Szwajcarskie Mrożone Wino",
    "Synbiotyk",
    "Snap-8tm",
    "Proteiny Jedwabiu",
    "Olej Z Wiśni",
    "Olej Z Pestek Malin",
    "Olej Z Pestek Arbuza",
    "Olej Z Nasion Żmijowca",
    "Olej Z Nasion Kawy",
    "Olej Z Meadowfoam",
    "Olej Z Marakui",
    "Olej Z Baobabu",
    "Olej Tamanu",
    "Olej Słonecznikowy",
    "Olej Monoi",
    "Olej Migdałowy",
    "Olej Kukui",
    "Olej Konopny",
    "Olej Kokosowy",
    "Olej Jojoba",
    "Olej Brzoskwiniowy",
    "Olej Arganowy",
    "Nektar Komórkowy",
    "Minerały Z Morza Martwego",
    "Melavoid",
    "Masło Kakaowe",
    "Linefill",
    "Koloidalna Platyna",
    "Jonizowane Srebro",
    "Hydromanil",
    "Hydrolat Z Zielonej Herbaty",
    "Hydrolat Z Neroli",
    "Eye Fective",
    "Ekstrakt Ze Złotej Algi",
    "Ekstrakt Ze Świetlika",
    "Ekstrakt Ze Śnieżnej Algi",
    "Ekstrakt Ze Śliwek Kakadu",
    "Ekstrakt Ze Smoczego Owocu",
    "Ekstrakt Z Żurawiny",
    "Ekstrakt Z Żeń Szenia",
    "Ekstrakt Z Zielonej Herbaty",
    "Ekstrakt Z Trawy Cytrynowej",
    "Ekstrakt Z Rumianku",
    "Ekstrakt Z Pomarańczy",
    "Ekstrakt Z Pigwy",
    "Ekstrakt Z Pereł Morskich",
    "Ekstrakt Z Orchidei",
    "Ekstrakt Z Opuncji Figowej",
    "Ekstrakt Z Oliwek",
    "Ekstrakt Z Ogórka",
    "Ekstrakt Z Nasion Lnu",
    "Ekstrakt Z Nasion Chia",
    "Ekstrakt Z Mirtu Cytrynowego",
    "Ekstrakt Z Miodu",
    "Ekstrakt Z Mimozy",
    "Ekstrakt Z Miłorzębu",
    "Ekstrakt Z Marakui",
    "Ekstrakt Z Mango",
    "Ekstrakt Z Lotosu",
    "Ekstrakt Z Limonki",
    "Ekstrakt Z Kwiatów Maku",
    "Ekstrakt Z Kurkumy",
    "Ekstrakt Z Koniczyny",
    "Ekstrakt Z Kokosa",
    "Ekstrakt Z Kiwi",
    "Ekstrakt Z Kawioru",
    "Ekstrakt Z Kakaowca",
    "Ekstrakt Z Imbiru",
    "Ekstrakt Z Guarany",
    "Ekstrakt Z Grejpfruta",
    "Ekstrakt Z Granatu",
    "Ekstrakt Z Goji",
    "Ekstrakt Z Dzikiej Róży",
    "Ekstrakt Z Czerwonej Herbaty",
    "Ekstrakt Z Czarnej Herbaty",
    "Ekstrakt Z Czarnego Tulipana",
    "Ekstrakt Z Cytryny",
    "Ekstrakt Z Bluszczu",
    "Ekstrakt Z Białych Winogron",
    "Ekstrakt Z Awokado",
    "Ekstrakt Z Ashwagandy",
    "Ekstrakt Z Arniki",
    "Ekstrakt Z Aloesu",
    "Ekstrakt Z Alg",
    "Dermasoft",
    "Darkout",
    "Cobiolift",
    "Algi",
    "Aktywny Węgiel Z Bambusa",
    "Aktywny Tlen",
    "Agascalm"

];

ingredients.sort((a, b) => a.localeCompare(b, 'pl', { sensitivity: 'base' }));