<template>
  <div class="box-card">
    <el-select
    @change="updateSelected"
    v-model="selectedIngredients" multiple placeholder="Select ingredients" style="width: 100%">
      <el-option v-for="(ingredient, index) in ingredients" :key="index" :label="ingredient" :value="ingredient">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { ingredients } from './data/ingredients.js';
export default {
  name: 'IngredientsList',
  data() {
    return {
      ingredients: ingredients,
      selectedIngredients: []
    }
  },
  methods: {
    updateSelected() {
      this.$emit('update', this.selectedIngredients);
    }

  }
}
</script>

<style scoped>
.box-card {
  width: 400px;
  margin: 20px;
}
</style>