export const products_features = {
    "products_features": [
        {
            "id": 4,
            "body_parties": [
                "Włosy",
                
            ],
            "skin_types": [
                "Łojotokowa",
                "Trądzikowa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Baicapil",
                "Kofeina",
                "Ekstrakt Z Kozieradki",
                "Ekstrakt Z Żeń Szenia",
                "Ekstrakt Z Rokitnika",
                "Ekstrakt Ze Skrzypu",
                "Ekstrakt Z Łopianu",
                "D Panthenol"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 8,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Minerały Z Morza Martwego"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 10,
            "body_parties": [
                "Twarz",
                "Oczy"
            ],
            "skin_types": [
                "Trądzikowa",
                "Tłusta",
                "Mieszana"
            ],
            "skin_problems": [
                "Cera Z Trądzikiem",
                "Cera Tłusta"
            ],
            "ingredients": [
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Kiwi",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Alg"
            ],
            "basic_active_ingredients": [
                "Aloes"
            ]
        },
        {
            "id": 28,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Z Przebarwieniami",
                "Szara",
                "Zmęczona"
            ],
            "skin_problems": [
                "Przebarwienia",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Melavoid",
                "Darkout",
                "Ekstrakt Z Ogórka",
                "Ekstrakt Z Grejpfruta",
                "D Panthenol",
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Olej Kokosowy"
            ],
            "basic_active_ingredients": [
                "Witaminy",
                "Witamina B",
                "Witamina C",
                "Witamina E",
                "D Panthenol",
                "Kwas Hialuronowy",
                "Kolagen"
            ]
        },
        {
            "id": 31,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Z Przebarwieniami",
                "Szara",
                "Zmęczona",
                "Normalna"
            ],
            "skin_problems": [
                "Przebarwienia",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Melavoid",
                "Darkout",
                "Ekstrakt Z Ogórka",
                "Ekstrakt Z Grejpfruta",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Witamina C",
                "Witamina B",
                "D Pantenol"
            ]
        },
        {
            "id": 37,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa",
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Problem Przebarwienia",
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Ekstrakt Ze Śliwek Kakadu",
                "Ekstrakt Z Wiśni",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Goji",
                "Olej Z Pestek Śliwek"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Witamina C",
                "D Pantenol"
            ]
        },
        {
            "id": 42,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Trądzikowa",
                "Wrażliwa",
                "Normalna"
            ],
            "skin_problems": [
                "Cera Z Trądzikiem",
                "Cera Wrażliwa Naczynkowa",
                "Cera Mieszana"
            ],
            "ingredients": [
                "Ekstrakt Z Alg",
                "Ekstrakt Z Mimozy",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Aloes"
            ]
        },
        {
            "id": 43,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa",
                "Naczynkowa"
            ],
            "skin_problems": [
                "Problem Odwodnienie",
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Ekstrakt Z Alg",
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Grejpfruta",
                "Ekstrakt Z Cytryny",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Miłorzębu"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Witamina C",
                "D Panthenol",
                "Kwasy Bha",
                "Kwasy Aha",
                "Kwas Mlekowy",
                "Kwas Tartarowy"
            ]
        },
        {
            "id": 44,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Trądzikowa",
                "Tłusta",
                "Mieszana"
            ],
            "skin_problems": [
                "Rozszerzone Pory",
                "Cera Z Trądzikiem",
                "Cera Mieszana"
            ],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Mimozy",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Alg"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 46,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Trądzikowa",
                "Wrażliwa",
                "Normalna"
            ],
            "skin_problems": [
                "Trądzik Pospolity",
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Ekstrakt Z Awokado",
                "Ekstrakt Z Alg",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Aloes",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 48,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Wrażliwa",
                "Naczynkowa",
                "Mieszana",
                "Tłusta"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa",
                "Rozszerzone Pory",
                "Cienie Obrzęki Pod Oczami",
                "Przebarwienia"
            ],
            "ingredients": [
                "Ekstrakt Z Cytryny",
                "Ekstrakt Z Kiwi",
                "Ekstrakt Z Dzikiej Róży",
                "Ekstrakt Z Miłorzębu",
                "Ekstrakt Z Alg",
                "Aloes"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwas Hialuronowy",
                "Ceramidy",
                "D Panthenol"
            ]
        },
        {
            "id": 49,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Mimozy"
            ],
            "basic_active_ingredients": [
                "Kolagen",
                "Elastyna"
            ]
        },
        {
            "id": 51,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Naczynkowa",
                "Wrażliwa",
                "Sucha",
                "Ziemista"
            ],
            "skin_problems": [
                "Utrata Jędrności",
                "Zmarszczki",
                "Przebarwienia"
            ],
            "ingredients": [
                "Ekstrakt Z Białych Winogron",
                "Ekstrakt Z Marakui",
                "Ekstrakt Z Mimozy",
                "Ekstrakt Z Alg",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Awokado",
                "Olej Słonecznikowy",
                "Olej Arganowy"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Elastyna",
                "Kolagen",
                "D Panthenol",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 52,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Trądzikowa",
                "Tłusta",
                "Mieszana"
            ],
            "skin_problems": [
                "Problem Trądzik",
                "Cera Tłusta",
                "Cera Mieszana"
            ],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Ekstrakt Z Zielonej Herbaty",
                "Aktywny Węgiel Z Bambusa",
                "Ekstrakt Z Alg",
                "Ekstrakt Z Arniki",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Aloes"
            ]
        },
        {
            "id": 58,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Tensup",
                "Kwas Hialuronowy",
                "Masło Shea",
                "Olej Arganowy",
                "Proteiny Jedwabiu",
                "Ceramidy",
                "Olej Słonecznikowy",
                "Ekstrakt Z Awokado",
                "Ekstrakt Z Nasion Lnu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Masło Shea",
                "Ceramidy"
            ]
        },
        {
            "id": 60,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Tensup",
                "Ekstrakt Z Awokado",
                "Kwas Hialuronowy",
                "Proteiny Jedwabiu",
                "Ceramidy",
                "Ekstrakt Z Nasion Lnu",
                "Olej Słonecznikowy",
                "Masło Shea",
                "Olej Arganowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Ceramidy",
                "Masło Shea"
            ]
        },
        {
            "id": 62,
            "body_parties": [],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Problem Zmarszczki",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Tensup",
                "Proteiny Jedwabiu",
                "Olej Słonecznikowy",
                "Masło Shea",
                "Olej Arganowy",
                "Ekstrakt Z Awokado",
                "Ekstrakt Z Nasion Lnu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Ceramidy"
            ]
        },
        {
            "id": 63,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Tensup",
                "Kwasy Hialuronowy",
                "Masło Shea",
                "Olej Arganowy",
                "Ekstrakt Z Awokado",
                "Proteiny Jedwabiu",
                "Ceramidy",
                "Ekstrakt Z Nasion Lnu",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Masło Shea",
                "Olej Arganowy",
                "Ceramidy",
                "D Panthenol"
            ]
        },
        {
            "id": 65,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Tensup",
                "Kwas Hialuronowy",
                "Masło Shea",
                "Olej Arganowy",
                "Ekstrakt Z Awokado",
                "Proteiny Jedwabiu",
                "Ceramidy",
                "Ekstrakt Z Nasion Lnu",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Masło Shea",
                "Ceramidy",
                "Kolagen",
                "Witamina E",
                "Witamina C",
                "Elastyna"
            ]
        },
        {
            "id": 66,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Problem Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Olej Arganowy",
                "Ekstrakt Z Opuncji Figowej",
                "Olej Makadamia",
                "Olej Słonecznikowy",
                "Olej Sezamowy"
            ],
            "basic_active_ingredients": [
                "Masło Shea",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 67,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa",
                "Naczynkowa",
                "Zmęczona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cera Wrażliwa Naczynkowa",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Ekstrakt Z Alg",
                "Ekstrakt Z Białych Winogron",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwas Hialuronowy",
                "D-Pantenol"
            ]
        },
        {
            "id": 69,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Trądzikowa",
                "Tłusta",
                "Mieszana"
            ],
            "skin_problems": [
                "Problem Trądzik",
                "Nadmierny Łojotok",
                "Rozszerzone Pory"
            ],
            "ingredients": [
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Alg",
                "Ekstrakt Z Kiwi",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Kwas Mlekowy"
            ]
        },
        {
            "id": 70,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Naczynkowa",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa",
                "Zmarszczki",
                "Przebarwienia",
                "Rozszerzone Pory"
            ],
            "ingredients": [
                "Ekstrakt Z Cytryny",
                "Ekstrakt Z Miłorzębu",
                "Ekstrakt Z Alg",
                "Ekstrakt Z Grejpfruta"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "D-Pantenol",
                "Kwas Hialuronowy",
                "Antyoksydanty"
            ]
        },
        {
            "id": 71,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Wrażliwa",
                "Naczynkowa"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Kwas Hialuronowy",
                "Ekstrakt Z Alg",
                "Ekstrakt Z Aloesu",
                "Proteiny Jedwabiu",
                "Olej Arganowy",
                "Masło Shea"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Kwas Askorbinowy",
                "D Pantenol",
                "Ceramidy",
                "Witamina E",
                "Witamina C",
                "Hydrolat"
            ]
        },
        {
            "id": 72,
            "body_parties": [],
            "skin_types": [
                "Naczynkowa",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Ekstrakt Z Grejpfruta",
                "Ekstrakt Z Miłorzębu",
                "Olej Arganowy",
                "Masło Shea",
                "Proteiny Jedwabiu"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Ceramidy",
                "Kwas Hialuronowy",
                "D Pantenol",
                "Aloes"
            ]
        },
        {
            "id": 73,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Trądzikowa",
                "Tłusta",
                "Mieszana"
            ],
            "skin_problems": [
                "Trądzik Pospolity",
                "Cera Z Trądzikiem"
            ],
            "ingredients": [
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Algi",
                "Ekstrakt Z Kiwi",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Ceramidy",
                "D Panthenol",
                "Witamina E",
                "Witamina C",
                "Aloes"
            ]
        },
        {
            "id": 74,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Sucha Odwodniona",
                "Problem Zmarszczki",
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Ekstrakt Z Mimozy",
                "Ekstrakt Z Lotosu",
                "Ekstrakt Z Nasion Lnu",
                "Olej Z Baobabu",
                "Kwas Hialuronowy",
                "Ekstrakt Z Oliwek"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Ceramidy",
                "D Panthenol",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 81,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Trądzikowa",
                "Mieszana",
                "Tłusta"
            ],
            "skin_problems": [
                "Trądzik Pospolity",
                "Problem Trądzik",
                "Cera Tłusta",
                "Rozszerzone Pory"
            ],
            "ingredients": [
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Kiwi",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Alg"
            ],
            "basic_active_ingredients": [
                "Aloes"
            ]
        },
        {
            "id": 85,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Trądzikowa",
                "Tłusta",
                "Mieszana"
            ],
            "skin_problems": [
                "Trądzik Pospolity",
                "Rozszerzone Pory",
                "Cera Tłusta",
                "Cera Mieszana"
            ],
            "ingredients": [
                "Ekstrakt Z Mimozy",
                "Ekstrakt Z Arniki"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 86,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa",
                "Naczynkowa",
                "Tłusta",
                "Mieszana"
            ],
            "skin_problems": [],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Proteiny Jedwabiu",
                "Ekstrakt Z Alg",
                "Ekstrakt Z Mimosa",
                "Olej Arganowy",
                "Aloes"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "D Pantenol",
                "Witamina C",
                "Witamina E"
            ]
        },
        {
            "id": 87,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Mieszana",
                "Tłusta",
                "Dojrzała",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Proteiny Jedwabiu",
                "Olej Słonecznikowy",
                "Olej Arganowy",
                "D Pantenol",
                "Ekstrakt Z Alg",
                "Ekstrakt Z Mimozy",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Witamina C",
                "Kwas Hialuronowy",
                "Ceramidy",
                "D Pantenol",
                "Aloes"
            ]
        },
        {
            "id": 88,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Normalna",
                "Wrażliwa",
                "Mieszana",
                "Sucha"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Ekstrakt Z Mimozy",
                "Ekstrakt Z Arniki"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 89,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Z Baobabu",
                "Olej Arganowy",
                "Masło Shea",
                "Olej Słonecznikowy",
                "Ekstrakt Z Kakaowca",
                "Ekstrakt Z Alg",
                "Witamina E"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 91,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Naczynkowa",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Proteiny Jedwabiu",
                "Ekstrakt Z Miłorzębu",
                "Kwas Hialuronowy",
                "Ceramidy",
                "Ekstrakt Z Alg",
                "Olej Arganowy",
                "Aloes"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwas Hialuronowy",
                "Ceramidy"
            ]
        },
        {
            "id": 92,
            "body_parties": [
                "Twarz",
                "Oczy"
            ],
            "skin_types": [
                "Trądzikowa",
                "Tłusta",
                "Mieszana"
            ],
            "skin_problems": [
                "Problem Trądzik",
                "Problem Rozszerzone Pory",
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Kiwi",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Alg"
            ],
            "basic_active_ingredients": [
                "Aloes"
            ]
        },
        {
            "id": 94,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Przesuszona"
            ],
            "skin_problems": [],
            "ingredients": [
                "Masło Shea",
                "Olej Arganowy",
                "Olej Z Baobabu",
                "Olej Słonecznikowy",
                "Ekstrakt Z Nasion Lnu"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 96,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Naczynkowa",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Ekstrakt Z Dzikiej Róży",
                "Ekstrakt Z Kiwi",
                "Ekstrakt Z Cytryny",
                "Ekstrakt Z Miłorzębu",
                "Ekstrakt Z Alg",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Awokado",
                "Masło Shea"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Elastyna",
                "Kolagen",
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        },
        {
            "id": 104,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Sucha",
                "Przesuszona"
            ],
            "skin_problems": [],
            "ingredients": [
                "Masło Shea",
                "Olej Arganowy",
                "Ekstrakt Z Pomarańczy",
                "Kwas Hialuronowy",
                "Ekstrakt Z Alg",
                "Kolagen",
                "Elastyna",
                "Proteiny Jedwabiu"
            ],
            "basic_active_ingredients": [
                "Masło Shea",
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna"
            ]
        },
        {
            "id": 107,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Zniszczona",
                "Dojrzała",
                "Zmęczona"
            ],
            "skin_problems": [
                "Cellulit"
            ],
            "ingredients": [
                "Ekstrakt Z Pomarańczy",
                "Ekstrakt Z Granatu",
                "Ekstrakt Z Guarany",
                "Ekstrakt Z Imbiru",
                "Kofeina"
            ],
            "basic_active_ingredients": [
                "Witamina A",
                "Witamina C",
                "Kolagen",
                "Elastyna"
            ]
        },
        {
            "id": 108,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa",
                "Naczynkowa",
                "Ziemista"
            ],
            "skin_problems": [
                "Cera Tłusta",
                "Zmarszczki",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Ekstrakt Z Białych Winogron",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Alg",
                "Ekstrakt Z Miłorzębu",
                "Olej Słonecznikowy",
                "Olej Arganowy"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwas Hialuronowy",
                "Ceramidy",
                "D Pantenol"
            ]
        },
        {
            "id": 109,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa",
                "Naczynkowa",
                "Ziemista"
            ],
            "skin_problems": [
                "Przebarwienia",
                "Cienie Obrzęki Pod Oczami",
                "Utrata Jędrności",
                "Zmarszczki"
            ],
            "ingredients": [
                "Ekstrakt Z Białych Winogron",
                "Ekstrakt Z Aloesu",
                "Proteiny Jedwabiu",
                "Olej Arganowy",
                "Masło Shea",
                "Ceramidy"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Ceramidy"
            ]
        },
        {
            "id": 114,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Sucha",
                "Przesuszona"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Ekstrakt Z Mango",
                "Ekstrakt Z Opuncji Figowej",
                "Ekstrakt Z Awokado",
                "Olej Arganowy",
                "Masło Shea",
                "Olej Słonecznikowy",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Mimozy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 115,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Odwodniona",
                "Zniszczona",
                "Przesuszona",
                "Łuszcząca"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Ekstrakt Z Lnu",
                "Ekstrakt Z Aloesu",
                "Kwas Hialuronowy",
                "Ceramidy"
            ],
            "basic_active_ingredients": [
                "Żel Aloesowy",
                "Kwas Hialuronowy",
                "Ceramidy"
            ]
        },
        {
            "id": 116,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Odwodniona",
                "Zniszczona",
                "Łuszcząca"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Lnu",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 120,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Przesuszona"
            ],
            "skin_problems": [
                "Problem Zmarszczki",
                "Cera Trądzikowa",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Olej Arganowy",
                "Masło Shea",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Awokado",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Opuncji Figowej"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 121,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Odwodniona",
                "Zniszczona",
                "Łuszcząca",
                "Przesuszona"
            ],
            "skin_problems": [
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Ekstrakt Z Lnu",
                "Olej Słonecznikowy",
                "Ekstrakt Z Gruszki",
                "Ekstrakt Z Aloesu",
                "Kwas Hialuronowy",
                "Ceramidy",
                "Ekstrakt Z Alg"
            ],
            "basic_active_ingredients": [
                "Ceramidy",
                "Kwas Hialuronowy",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 122,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Odwodniona",
                "Zniszczona",
                "Łuszcząca",
                "Przesuszona"
            ],
            "skin_problems": [
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Ekstrakt Z Siemienia Lnianego",
                "Ekstrakt Z Aloesu",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Aloes"
            ]
        },
        {
            "id": 127,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Odwodniona",
                "Zniszczona",
                "Łuszcząca",
                "Przesuszona"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Lnu",
                "Ekstrakt Z Aloesu",
                "Kwas Hialuronowy",
                "Kolagen"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Aloes"
            ]
        },
        {
            "id": 129,
            "body_parties": [
                "Twarz",
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Problem Zmarszczki"
            ],
            "ingredients": [
                "Ekstrakt Z Pomarańczy",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Komórki Macierzyste",
                "Kwas Hialuronowy",
                "Aloes"
            ]
        },
        {
            "id": 130,
            "body_parties": [
                "Twarz",
                "Ciało"
            ],
            "skin_types": [
                "Sucha",
                "Mieszana",
                "Tłusta"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa",
                "Problem Zmarszczki"
            ],
            "ingredients": [
                "Woda Różana",
                "Ekstrakt Z Dzikiej Róży"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Aloes"
            ]
        },
        {
            "id": 131,
            "body_parties": [],
            "skin_types": [
                "Odwodniona",
                "Zniszczona",
                "Łuszcząca",
                "Przesuszona"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Witamina A",
                "Witamina C",
                "Witamina B"
            ]
        },
        {
            "id": 132,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Wrażliwa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Alg",
                "Ekstrakt Z Lnu",
                "Ekstrakt Z Lipy",
                "Ekstrakt Z Mięty"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 133,
            "body_parties": [],
            "skin_types": [
                "Sucha",
                "Normalna"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Ceramidy",
                "Algi"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        },
        {
            "id": 134,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Wrażliwa",
                "Naczynkowa"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Ekstrakt Z Dzikiej Róży",
                "Ekstrakt Z Miłorzębu",
                "Ekstrakt Z Grejpfruta",
                "D Pantenol",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "D Panthenol",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 135,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Trądzikowa",
                "Mieszana",
                "Tłusta"
            ],
            "skin_problems": [
                "Problem Trądzik",
                "Cera Z Trądzikiem"
            ],
            "ingredients": [
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Arniki",
                "Ekstrakt Z Bambusa",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Aloes"
            ]
        },
        {
            "id": 137,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Olej Arganowy",
                "Masło Shea",
                "Olej Słonecznikowy",
                "Ekstrakt Z Pomarańczy",
                "Ekstrakt Z Grejpfruta",
                "Ekstrakt Z Hibiskusa"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Witamina C",
                "Masło Shea"
            ]
        },
        {
            "id": 138,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Przesuszona",
                "Mieszana",
                "Tłusta"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Słonecznikowy",
                "Ekstrakt Z Imbiru",
                "Ekstrakt Z Czerwonej Herbaty",
                "Ekstrakt Z Goździka",
                "Ekstrakt Z Cynamonu"
            ],
            "basic_active_ingredients": [
                "Antyoksydanty"
            ]
        },
        {
            "id": 139,
            "body_parties": [
                "Twarz",
                "Oczy",
                "Usta",
                "Szyja/Dekolt"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Kwas Hialuronowy",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Mimozy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Aloes"
            ]
        },
        {
            "id": 141,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [],
            "skin_problems": [
                "Przebarwienia",
                "Zmarszczki",
                "Cera Wrażliwa Naczynkowa",
                "Cienie Obrzęki Pod Oczami",
                "Problemy Zmarszczkie"
            ],
            "ingredients": [
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Mimozy",
                "Ekstrakt Z Arniki",
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Lilii Wodnej"
            ],
            "basic_active_ingredients": [
                "Aloes"
            ]
        },
        {
            "id": 142,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Olej Słonecznikowy",
                "Ekstrakt Z Nasion Lnu",
                "Olej Arganowy",
                "Olej Kokosowy",
                "Masło Shea",
                "Skwalan",
                "Ekstrakt Z Owsa",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Witamina A",
                "Witamina E",
                "Kwas Hialuronowy",
                "D Pantenol"
            ]
        },
        {
            "id": 143,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [
                "Przesuszona",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Olej Słonecznikowy",
                "Olej Arganowy",
                "Olej Kokosowy",
                "Masło Shea",
                "Skwalan",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Owsa",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Witamina A",
                "Witamina E",
                "D Panthenol",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 145,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Problem Swędzenie Skóry",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Olej Słonecznikowy",
                "Olej Kokosowy",
                "Olej Arganowy",
                "Masło Shea",
                "Skwalan",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Owsa",
                "Ekstrakt Z Aloesu",
                "D Panthenol",
                "Hydromanil"
            ],
            "basic_active_ingredients": [
                "Witamina A",
                "Witamina E",
                "Kwas Hialuronowy",
                "D Pantenol"
            ]
        },
        {
            "id": 146,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Cera Wrażliwa"
            ],
            "ingredients": [
                "Olej Słonecznikowy",
                "Masło Shea",
                "Olej Arganowy",
                "Olej Kokosowy",
                "Skwalan",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Owsa",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Witamina A",
                "Witamina E",
                "Kwas Hialuronowy",
                "D Panthenol",
                "Spf",
                "Retinol"
            ]
        },
        {
            "id": 150,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Linefill",
                "Ekstrakt Z Orchidei",
                "Algi",
                "Kwas Hialuronowy",
                "Skwalan"
            ],
            "basic_active_ingredients": [
                "Skwalan",
                "Kwas Hialuronowy",
                "D Panthenol",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 151,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Linefill",
                "Ekstrakt Z Orchidei",
                "Kwas Hialuronowy",
                "Skwalan",
                "Ekstrakt Z Alg",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "Skwalan",
                "D Panthenol",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 152,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Linefill",
                "Ekstrakt Z Orchidei",
                "Algi",
                "Skwalan"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 153,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Linefill",
                "Ekstrakt Z Orchidei",
                "Algi",
                "Kwas Hialuronowy",
                "Skwalan"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Skwalan"
            ]
        },
        {
            "id": 155,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [
                "Dojrzała",
                "Normalna"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Linefill",
                "Algi",
                "Ekstrakt Z Orchidei",
                "Kwas Hialuronowy",
                "Skwalan",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Skwalan",
                "D Panthenol",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 156,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała",
                "Normalna"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Linefill",
                "Ekstrakt Z Orchidei",
                "Algi",
                "Kwas Hialuronowy",
                "Skwalan"
            ],
            "basic_active_ingredients": [
                "Elastyna",
                "Kolagen",
                "Kwas Hialuronowy",
                "Skwalan",
                "D Panthenol"
            ]
        },
        {
            "id": 167,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Mieszana",
                "Tłusta",
                "Trądzikowa"
            ],
            "skin_problems": [
                "Cera Tłusta",
                "Cera Mieszana",
                "Cera Z Trądzikiem"
            ],
            "ingredients": [
                "Hydromanil",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "D Pantenol",
                "Kwas Migdałowy",
                "Olej Migdałowy"
            ]
        },
        {
            "id": 168,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Zniszczona",
                "Wrażliwa",
                "Sucha",
                "Odwodniona",
                "Normalna",
                "Mieszana"
            ],
            "skin_problems": [
                "Problem Odwodnienie",
                "Zmarszczki"
            ],
            "ingredients": [
                "Kwas Hialuronowy",
                "Ekstrakt Z Ogórka",
                "Hydromanil",
                "Ekstrakt Z Nasion Lnu",
                "D Pantenol"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        },
        {
            "id": 169,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Mieszana"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Ekstrakt Z Alg"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Kwas Mlekowy",
                "D Panthenol"
            ]
        },
        {
            "id": 170,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Zmęczona",
                "Szara",
                "Ziemista"
            ],
            "skin_problems": [
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Aktywny Tlen",
                "Sepitonic M3",
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Ekstrakt z Żeń Szenia",
                "Ceramidy",
                "Algi"
            ],
            "basic_active_ingredients": [
                "Aktywny Tlen",
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Ceramidy"
            ]
        },
        {
            "id": 171,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Ziemista"
            ],
            "skin_problems": [
                "Cera Ziemista Zmęczona",
                "Utrata Jędrności",
                "Zmarszczki"
            ],
            "ingredients": [
                "Ekstrakt Ze Śliwek Kakadu",
                "Ekstrakt Z Wiśni",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Jagód Goji",
                "Olej Z Pestek Śliwek",
                "Olej Z Nasion Chia",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Elastyna",
                "Kolagen",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 172,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Aktywny Tlen"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 173,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Dojrzała",
                "Normalna"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Ekstrakt Z Oliwek",
                "Minerały Z Morza Martwego",
                "Kwas Hialuronowy",
                "Ceramidy",
                "Olej Z Baobabu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Ceramidy",
                "D Pantenol",
                "Witaminy",
                "Witamina C"
            ]
        },
        {
            "id": 174,
            "body_parties": [
                "Włosy",
                
            ],
            "skin_types": [
                "Zniszczona",
                "Sucha"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Monoi",
                "Olej Kokosowy",
                "Olej Słonecznikowy",
                "Ekstrakt Z Żeń Szenia",
                "Proteiny Jedwabiu",
                "Ekstrakt Z Pokrzywy"
            ],
            "basic_active_ingredients": [
                "D Panthenol"
            ]
        },
        {
            "id": 175,
            "body_parties": [
                "Włosy"
            ],
            "skin_types": [
                "Zniszczona",
                "Sucha"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Kokosowy",
                "Ekstrakt Z Awokado",
                "Ekstrakt Z Żeń Szenia",
                "Ekstrakt Z Aloesu",
                "Kwas Hialuronowy",
                "D Panthenol"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 178,
            "body_parties": [
                "Włosy",

            ],
            "skin_types": [
                "Zniszczona",
                "Sucha"
            ],
            "skin_problems": [],
            "ingredients": [
                "Kofeina",
                "Ekstrakt Z Kozieradki",
                "Ekstrakt Z Rokitnika",
                "Ekstrakt Z Żeń Szenia",
                "Ekstrakt Ze Skrzypu",
                "Ekstrakt Z Łopianu",
                "D Pantenol"
            ],
            "basic_active_ingredients": [
                "Komórki Macierzyste"
            ]
        },
        {
            "id": 179,
            "body_parties": [
                "Włosy",
                
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Baicapil",
                "Keracyn",
                "Kofeina",
                "Ekstrakt Z Kozieradki",
                "Ekstrakt Z Rokitnika",
                "Ekstrakt Z Żeń Szenia",
                "Ekstrakt Ze Skrzypu",
                "Ekstrakt Z Łopianu",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "Komórki Macierzyste",
                "Kofeina",
                "D Panthenol"
            ]
        },
        {
            "id": 184,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Tłusta",
                "Mieszana",
                "Trądzikowa"
            ],
            "skin_problems": [
                "Rozszerzone Pory",
                "Nadmierny Łojotok",
                "Cera Z Trądzikiem"
            ],
            "ingredients": [
                "Aktywny Węgiel Z Bambusa",
                "Jonizowane Srebro",
                "Hydromanil",
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Kiwi"
            ],
            "basic_active_ingredients": [
                "Antyoksydanty"
            ]
        },
        {
            "id": 186,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Dermasoft",
                "Jonizowane Srebro",
                "Hydromanil",
                "Ekstrakt Z Arniki",
                "Ekstrakt Z Alg"
            ],
            "basic_active_ingredients": [
                "D Pantenol",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 187,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Dermasoft",
                "Jonizowane Srebro",
                "Kwas Hialuronowy",
                "Hydromanil",
                "Ekstrakt Z Arniki",
                "Ekstrakt Z Lilii Wodnej"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        },
        {
            "id": 192,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [
                "Sucha",
                "Normalna",
                "Odwodniona"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Olej Migdałowy",
                "Olej Słonecznikowy",
                "Masło Shea",
                "Kolagen",
                "Elastyna",
                "Kwas Hialuronowy",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Masło Shea",
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna"
            ]
        },
        {
            "id": 193,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [
                "Każdy rodzaj skóry"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Arganowy",
                "Olej Słonecznikowy",
                "Masło Shea",
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Aloes"
            ]
        },
        {
            "id": 194,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Olej Arganowy",
                "Olej Słonecznikowy",
                "Masło Shea",
                "Kolagen",
                "Elastyna",
                "Kwas Hialuronowy",
                "Olej Migdałowy",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Nasion Lnu"
            ],
            "basic_active_ingredients": [
                "Mocznik",
                "Aloes",
                "Kolagen",
                "Elastyna",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 195,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Olej Arganowy",
                "Olej Słonecznikowy",
                "Masło Shea",
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Ekstrakt Z Lnu",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Kolagen",
                "Elastyna",
                "Kwas Hialuronowy",
                "Olej Arganowy",
                "Olej Słonecznikowy",
                "Masło Shea",
                "Aloes"
            ]
        },
        {
            "id": 200,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Sucha",
                "Zniszczona",
                "Dojrzała",
                "Normalna",
                "Odwodniona"
            ],
            "skin_problems": [
                "Problem Odwodnienie",
                "Utrata Jędrności",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Aloes",
                "Ekstrakt Z Lnu",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Wiśni",
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Masło Shea",
                "Olej Arganowy"
            ],
            "basic_active_ingredients": [
                "Aloes",
                "Kolagen",
                "Elastyna",
                "Kwas Hialuronowy",
                "Masło Shea",
                "Olej Arganowy",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 201,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Ekstrakt Z Lnu",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Wiśni",
                "Olej Arganowy",
                "Masło Shea"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Aloes",
                "Masło Shea"
            ]
        },
        {
            "id": 202,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Ekstrakt Z Nasion Lnu",
                "Aloes",
                "Ekstrakt Z Mango",
                "Olej Arganowy",
                "Masło Shea"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Aloes",
                "Masło Shea"
            ]
        },
        {
            "id": 203,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Przesuszona",
                "Odwodniona"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Olej Arganowy",
                "Masło Shea",
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Ekstrakt Z Lnu",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Mango",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Olej Arganowy",
                "Masło Shea",
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Aloes",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 205,
            "body_parties": [
                "Twarz",
                "Ciało"
            ],
            "skin_types": [
                "Zniszczona",
                "Zmęczona",
                "Ziemista",
                "Z Przebarwieniami",
                "Wrażliwa",
                "Trądzikowa",
                "Tłusta",
                "Szara",
                "Sucha",
                "Przesuszona",
                "Odwodniona",
                "Normalna",
                "Naczynkowa",
                "Mieszana",
                "Łuszcząca",
                "Łojotokowa",
                "Dojrzała"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Ekstrakt Z Aloesu",
                "Minerały Z Morza Martwego"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Aloes"
            ]
        },
        {
            "id": 206,
            "body_parties": [],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Olej Z Pestek Malin",
                "Składnik Hydromanil",
                "Skwalan",
                "Ceramidy"
            ],
            "basic_active_ingredients": [
                "Antyoksydanty",
                "Skwalan",
                "Kwas Hialuronowy",
                "Komórki Macierzyste",
                "D Panthenol",
                "Witamina E",
                "Witamina C",
                "Ceramidy"
            ]
        },
        {
            "id": 207,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Odwodniona",
                "Mieszana",
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Przebarwienia",
                "Cienie Obrzęki Pod Oczami",
                "Cera Sucha Odwodniona",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Hydromanil",
                "Olej Z Pestek Malin",
                "Olej Migdałowy",
                "Ekstrakt Z Liofilizowanych Malin",
                "Komórki Macierzyste Z Borówki Brusznicy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Skwalan",
                "Peptydy",
                "Komórki Macierzyste"
            ]
        },
        {
            "id": 209,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Odwodniona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cera Sucha Odwodniona",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Ekstrakt Z Malin",
                "Ceramidy",
                "Komórki Macierzyste",
                "Kwas Hialuronowy",
                "D-Pantenol",
                "Hydromanil",
                "Skwalan",
                "Olej Z Pestek Malin"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Ceramidy",
                "Skwalan",
                "D Pantenol",
                "Komórki Macierzyste",
                "Karoten",
                "Witaminy"
            ]
        },
        {
            "id": 210,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Guarana",
                "Olej Migdałowy",
                "Olej Kokosowy",
                "Ekstrakt Z Kokosa",
                "Ekstrakt Z Alg"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 211,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Przebarwienia",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Ekstrakt Z Algi",
                "Olej Kokosowy",
                "Guarana"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 212,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Cellulit"
            ],
            "ingredients": [
                "Guarana",
                "Ekstrakt Z Algi",
                "Ekstrakt Z Kokosa"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 217,
            "body_parties": [
                "Ciało",
                "Dłonie"
            ],
            "skin_types": [],
            "skin_problems": [
                "Problem Zmarszczki",
                "Cera Sucha Odwodniona",
                "Problem Trądzik"
            ],
            "ingredients": [
                "Olej Arganowy",
                "Olej Słonecznikowy",
                "Masło Shea",
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Aloes"
            ]
        },
        {
            "id": 218,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Odwodniona",
                "Tłusta",
                "Mieszana"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 219,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Odwodniona"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Kwas Hialuronowy",
                "Komórki Macierzyste"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Komórki Macierzyste",
                "Skwalan",
                "D Pantenol"
            ]
        },
        {
            "id": 222,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Aloesu",
                "Kwas Hialuronowy",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "D Panthenol",
                "Kwas Hialuronowy",
                "Aloes"
            ]
        },
        {
            "id": 223,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Tłusta",
                "Mieszana",
                "Trądzikowa"
            ],
            "skin_problems": [
                "Rozszerzone Pory",
                "Nadmierny Łojotok",
                "Cera Tłusta",
                "Cera Mieszana",
                "Cera Z Trądzikiem"
            ],
            "ingredients": [
                "Aktywny Węgiel Z Bambusa",
                "Jonizowane Srebro",
                "Ekstrakt Z Arniki",
                "Ekstrakt Z Kiwi"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 224,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Zmarszczki"
            ],
            "ingredients": [],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 225,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Odwodniona"
            ],
            "skin_problems": [
                " Problem Odwodnienie",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Olej Monoi",
                "Olej Migdałowy",
                "Masło Shea",
                "Kwas Hialuronowy",
                "Hydromanil",
                "Ekstrakt Z Alg",
                "D Pantenol",
                "Ekstrakt Z Orchidei"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Pantenol",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 226,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Odwodniona"
            ],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 227,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Dojrzała",
                "Odwodniona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cera Dojrzała",
                "Problem Odwodnienie"
            ],
            "ingredients": [],
            "basic_active_ingredients": [
                "Peptydy",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 230,
            "body_parties": [],
            "skin_types": [
                "Zniszczona",
                "Zmęczona",
                "Ziemista",
                "Z Przebarwieniami",
                "Wrażliwa",
                "Trądzikowa",
                "Tłusta",
                "Szara",
                "Sucha",
                "Przesuszona",
                "Odwodniona",
                "Normalna",
                "Naczynkowa",
                "Mieszana",
                "Łuszcząca",
                "Łojotokowa",
                "Dojrzała"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Ekstrakt Z Marakui",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Aloesu",
                "Kwas Hialuronowy",
                "Olej Migdałowy",
                "Olej Konopny"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Aloes"
            ]
        },
        {
            "id": 232,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Marakui",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Aloesu",
                "Kwas Hialuronowy",
                "Olej Migdałowy",
                "Olej Konopny"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 234,
            "body_parties": [
                "Ciało",
                "Dłonie"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 235,
            "body_parties": [],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 236,
            "body_parties": [
                "Ciało",
                "Dłonie"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 241,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Trądzikowa",
                "Wrażliwa",
                "Normalna"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa",
                "Problem Trądzik"
            ],
            "ingredients": [
                "Ekstrakt Z Alg",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Mimozy"
            ],
            "basic_active_ingredients": [
                "Aloes"
            ]
        },
        {
            "id": 242,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Trądzikowa",
                "Mieszana",
                "Tłusta"
            ],
            "skin_problems": [
                "Cera Z Trądzikiem",
                "Cera Mieszana",
                "Cera Tłusta"
            ],
            "ingredients": [
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Kiwi",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Alg"
            ],
            "basic_active_ingredients": [
                "Aloes"
            ]
        },
        {
            "id": 244,
            "body_parties": [
                "Twarz",
                "Oczy",
                "Usta",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Cienie Obrzęki Pod Oczami"
            ],
            "ingredients": [
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Mimozy",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Aloes"
            ]
        },
        {
            "id": 245,
            "body_parties": [],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Odwodniona",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Kwas Hialuronowy",
                "Ekstrakt Z Ogórka",
                "Hydromanil",
                "Ekstrakt Z Nasion Lnu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Pantenol"
            ]
        },
        {
            "id": 246,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Mieszana",
                "Tłusta",
                "Trądzikowa"
            ],
            "skin_problems": [
                "Przebarwienia",
                "Nadmierny Łojotok",
                "Cera Trądzikowa"
            ],
            "ingredients": [
                "Kwas Migdałowy",
                "D Panthenol",
                "Ekstrakt Z Aloesu",
                "Hydromanil"
            ],
            "basic_active_ingredients": [
                "D Panthenol"
            ]
        },
        {
            "id": 247,
            "body_parties": [
                "Stopy"
            ],
            "skin_types": [
                "Sucha"
            ],
            "skin_problems": [
                "Przebarwienia",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Ekstrakt Z Arniki",
                "Ekstrakt Z Nasion Lnu"
            ],
            "basic_active_ingredients": [
                "Mocznik"
            ]
        },
        {
            "id": 248,
            "body_parties": [
                "Stopy"
            ],
            "skin_types": [
                "Sucha",
                "Łuszcząca"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Konopny",
                "Ekstrakt Z Arniki",
                "Sól Z Morza Martwego",
                "Mocznik"
            ],
            "basic_active_ingredients": [
                "Mocznik",
                "Olej Konopny"
            ]
        },
        {
            "id": 249,
            "body_parties": [],
            "skin_types": [
                "Sucha"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Konopny",
                "Jonizowane Srebro",
                "Mocznik",
                "Kwas Hialuronowy",
                "Ekstrakt Z Arniki",
                "Ekstrakt Z Nasion Lnu"
            ],
            "basic_active_ingredients": [
                "Mocznik",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 250,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Wrażliwa",
                "Naczynkowa"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa",
                "Przebarwienia",
                "Zmarszczki"
            ],
            "ingredients": [
                "Ekstrakt Z Dzikiej Róży",
                "Beta-karoten"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwas Askorbinowy",
                "D Panthenol",
                "Kwas Askorbinowy"
            ]
        },
        {
            "id": 251,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Wrażliwa",
                "Naczynkowa"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Ekstrakt Z Dzikiej Róży"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Beta-Karoten",
                "Kwas Askorbinowy",
                "D Pantenol"
            ]
        },
        {
            "id": 252,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [
                "Naczynkowa",
                "Wrażliwa",
                "Sucha",
                "Ziemista"
            ],
            "skin_problems": [
                "Cienie Obrzęki Pod Oczami",
                "Cera Wrażliwa Naczynkowa",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Eye Fective",
                "Ceramidy",
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Olej Monoi",
                "Olej Konopny"
            ],
            "basic_active_ingredients": [
                "Ceramidy",
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "D Pantenol"
            ]
        },
        {
            "id": 254,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Olej Konopny",
                "Olej Makadamia",
                "Ekstrakt Z Kiełków Pszenicy",
                "Ekstrakt Z Aloesu",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Aloes"
            ]
        },
        {
            "id": 260,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Kwas Hialuronowy",
                "D Pantenol",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Pantenol",
                "Aloes"
            ]
        },
        {
            "id": 261,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Odwodniona"
            ],
            "skin_problems": [
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Lilii Wodnej"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol",
                "Aloes"
            ]
        },
        {
            "id": 264,
            "body_parties": [],
            "skin_types": [
                "Sucha",
                "Zniszczona"
            ],
            "skin_problems": [],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Ekstrakt Z Awokado",
                "Aloes",
                "Kolagen",
                "Proteiny Jedwabiu",
                "Ekstrakt Z Żeń Szenia",
                "Ekstrakt Z Skrzypu Polnego",
                "Ekstrakt Z Tataraku"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 265,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Hydromanil",
                "Kwas Hialuronowy",
                "Ekstrakt Z Aloesu",
                "D Pantenol"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Aloes",
                "D Panthenol",
                "Witamina B"
            ]
        },
        {
            "id": 268,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Kwas Hialuronowy",
                "Hydrolat",
                "D Panthenol",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol",
                "Aloes",
                "Witamina B"
            ]
        },
        {
            "id": 270,
            "body_parties": [
                "Ciało",
                "Twarz",
                "Włosy"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Hydromanil",
                "Ekstrakt Z Żeń Szenia",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Nasion Lnu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        },
        {
            "id": 271,
            "body_parties": [
                "Włosy",
                
            ],
            "skin_types": [
                "Zniszczona",
                "Sucha"
            ],
            "skin_problems": [],
            "ingredients": [
                "Proteiny Jedwabiu",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "D Pantenol"
            ]
        },
        {
            "id": 272,
            "body_parties": [
                "Włosy",

            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Baicapil",
                "Keracyn",
                "Kofeina",
                "Ekstrakt Z Kozieradki",
                "Ekstrakt Z Rokitnika",
                "Ekstrakt Z Żeń Szenia",
                "Ekstrakt Ze Skrzypu",
                "Ekstrakt Z Łopianu"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 274,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Sucha",
                "Odwodniona",
                "Normalna"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Hydrolat Z Zielonej Herbaty"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Kwas Salicylowy"
            ]
        },
        {
            "id": 275,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Dojrzała",
                "Odwodniona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Snap-8tm",
                "Kwas Hialuronowy",
                "Ekstrakt Z Zielonej Herbaty"
            ],
            "basic_active_ingredients": [
                "Peptydy Biomimetyczne",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 278,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [
                "Naczynkowa",
                "Wrażliwa",
                "Sucha",
                "Ziemista"
            ],
            "skin_problems": [
                "Cienie Obrzęki Pod Oczami",
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Eye Fective",
                "Ceramidy",
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Olej Monoi",
                "Olej Konopny"
            ],
            "basic_active_ingredients": [
                "Ceramidy",
                "Kwas Hialuronowy",
                "Koenzym Q10"
            ]
        },
        {
            "id": 279,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Sucha",
                "Normalna",
                "Mieszana",
                "Tłusta"
            ],
            "skin_problems": [
                "Trądzik Pospolity",
                "Zmarszczki",
                "Przebarwienia",
                "Utrata Jędrności",
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Olej Z Wiśni",
                "Olej Migdałowy",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Witaminy",
                "Kwasy Aha"
            ]
        },
        {
            "id": 280,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Każdego rodzaju cery",
                "Wrażliwa",
                "Naczynkowa"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cera Wrażliwa Naczynkowa",
                "Przebarwienia",
                "Cera Dojrzała",
                "Cera Z Trądzikiem",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Olej Z Wiśni",
                "Olej Migdałowy",
                "Kwas Hialuronowy",
                "Ceramidy"
            ],
            "basic_active_ingredients": [
                "Witaminy",
                "Witamina B",
                "Witamina A",
                "Witamina C",
                "D Pantenol",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 281,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Odwodniona",
                "Wrażliwa",
                "Naczynkowa"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Przebarwienia",
                "Cera Wrażliwa Naczynkowa",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Olej Z Wiśni",
                "Hydromanil"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Witamina A",
                "Witamina E",
                "Kwas Hialuronowy",
                "Ceramidy",
                "D Panthenol"
            ]
        },
        {
            "id": 282,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Wrażliwa",
                "Naczynkowa"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cera Dojrzała",
                "Przebarwienia",
                "Cera Wrażliwa Naczynkowa",
                "Cera Z Trądzikiem"
            ],
            "ingredients": [
                "Olej Z Wiśni",
                "Olej Migdałowy",
                "Kwas Hialuronowy",
                "D Pantenol",
                "Ceramidy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Ceramidy",
                "Witamina A",
                "Witamina B",
                "Witamina E"
            ]
        },
        {
            "id": 285,
            "body_parties": [
                "Włosy",

            ],
            "skin_types": [
                "Sucha",
                "Zniszczona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cera Sucha Odwodniona",
                "Problem Zmarszczki"
            ],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Olej Arganowy",
                "Proteiny Jedwabiu",
                "Masło Shea",
                "Ekstrakt Z Szałwii",
                "Ekstrakt Z Skrzypu Polnego",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Korzenia Łopianu",
                "Ekstrakt Z Melisy"
            ],
            "basic_active_ingredients": [
                "Kolagen",
                "Elastyna"
            ]
        },
        {
            "id": 286,
            "body_parties": [
                "Włosy",

            ],
            "skin_types": [
                "Normalna",
                "Tłusta"
            ],
            "skin_problems": [
                "Nadmierny Łojotok"
            ],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Ekstrakt Z Trawy Cytrynowej",
                "Ekstrakt Z Grejpfruta",
                "Ekstrakt Z Bambusa",
                "Proteiny Jedwabiu",
                "Ekstrakt Z Szałwii",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Korzenia Łopianu",
                "Ekstrakt Z Melisy"
            ],
            "basic_active_ingredients": [
                "Kolagen",
                "Hydrolat"
            ]
        },
        {
            "id": 295,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Zmęczona",
                "Ziemista",
                "Szara",
                "Odwodniona"
            ],
            "skin_problems": [
                "Cera Ziemista Zmęczona",
                "Cera Sucha Odwodniona",
                "Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Aktywny Tlen",
                "Kwas Hialuronowy",
                "Ekstrakt Z Żeń Szenia",
                "Koenzym Q10"
            ],
            "basic_active_ingredients": [
                "Aktywny Tlen",
                "Kwas Hialuronowy",
                "Koenzym Q10",
                "Ceramidy"
            ]
        },
        {
            "id": 296,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Odwodniona"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Z Pestek Malin",
                "Olej Migdałowy",
                "Hydromanil",
                "Skwalan",
                "Ceramidy",
                "Komórki Macierzyste"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Ceramidy",
                "D Pantenol",
                "Komórki Macierzyste"
            ]
        },
        {
            "id": 297,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Tłusta",
                "Mieszana",
                "Trądzikowa"
            ],
            "skin_problems": [
                "Nadmierny Łojotok",
                "Rozszerzone Pory",
                "Problem Trądzik"
            ],
            "ingredients": [
                "Aktywny Węgiel Z Bambusa",
                "Jonizowane Srebro",
                "Ekstrakt Z Kiwi",
                "Ekstrakt Z Zielonej Herbaty"
            ],
            "basic_active_ingredients": [
                "Hydromanil"
            ]
        },
        {
            "id": 298,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Naczynkowa",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Ekstrakt Z Dzikiej Róży",
                "Ekstrakt Z Arniki"
            ],
            "basic_active_ingredients": [
                "Witamina C"
            ]
        },
        {
            "id": 299,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Linefill",
                "Ekstrakt Z Orchidei",
                "Kwas Hialuronowy",
                "Skwalan",
                "Algi"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 300,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała",
                "Odwodniona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Snap-8tm",
                "Cobiolift",
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Olej Monoi"
            ],
            "basic_active_ingredients": [
                "Koenzym Q10",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 301,
            "body_parties": [],
            "skin_types": [
                "Dojrzała",
                "Odwodniona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Snap-8tm",
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Olej Monoi",
                "Cobiolift"
            ],
            "basic_active_ingredients": [
                "Peptydy Biomimetyczne",
                "Koenzym Q10",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 302,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [
                "Odwodniona"
            ],
            "skin_problems": [
                "Zmarszczki"
            ],
            "ingredients": [
                "Snap-8tm",
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Olej Monoi",
                "Cobiolift"
            ],
            "basic_active_ingredients": [
                "Skwalan",
                "Kwasy Aha",
                "Kwas Salicylowy",
                "Witamina C"
            ]
        },
        {
            "id": 305,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Normalna",
                "Wrażliwa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Alg",
                "Ekstrakt Z Arniki"
            ],
            "basic_active_ingredients": [
                "Peptydy",
                "D Panthenol",
                "Kwas Salicylowy"
            ]
        },
        {
            "id": 306,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Konopny",
                "Hydrolat Z Konopii",
                "Hydromanil",
                "Kwas Hialuronowy",
                "Aloes",
                "Masło Shea"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Masło Shea",
                "D Panthenol"
            ]
        },
        {
            "id": 307,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Konopny",
                "Masło Shea",
                "Hydromanil",
                "Kwas Hialuronowy",
                "Hydrolat Z Konopi",
                "D Pantenol",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Masło Shea",
                "Kwas Hialuronowy",
                "D Pantenol",
                "Aloes"
            ]
        },
        {
            "id": 308,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Olej Konopny",
                "Hydrolat",
                "Masło Shea",
                "Hydromanil",
                "Kwas Hialuronowy",
                "Aloes",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Skwalan",
                "D Pantenol",
                "Aloes",
                "Witamina E",
                "Kwas Hialuronowy",
                "Masło Shea"
            ]
        },
        {
            "id": 314,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Zmęczona",
                "Ziemista",
                "Szara",
                "Przesuszona",
                "Odwodniona"
            ],
            "skin_problems": [
                "Cera Ziemista Zmęczona",
                "Problem Zmarszczki"
            ],
            "ingredients": [
                "Aktywny Tlen",
                "Kwas Hialuronowy",
                "Koenzym Q10",
                "Ekstrakt Z Żeń Szenia"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Koenzym Q10",
                "Ceramidy"
            ]
        },
        {
            "id": 315,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Trądzikowa",
                "Mieszana",
                "Tłusta"
            ],
            "skin_problems": [
                "Cera Z Trądzikiem",
                "Cera Mieszana",
                "Cera Tłusta",
                "Rozszerzone Pory"
            ],
            "ingredients": [
                "Ekstrakt Z Alg",
                "Minerały Z Morza Martwego",
                "Ekstrakt Z Bambusa",
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Kiwi",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Aloes"
            ]
        },
        {
            "id": 316,
            "body_parties": [],
            "skin_types": [
                "Naczynkowa",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Ekstrakt Z Dzikiej Róży",
                "Ekstrakt Z Arniki"
            ],
            "basic_active_ingredients": [
                "Witamina C"
            ]
        },
        {
            "id": 317,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Problem Zmarszczki",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Tensup",
                "Masło Shea",
                "Olej Arganowy"
            ],
            "basic_active_ingredients": [
                "Masło Shea",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 319,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [
                "Sucha",
                "Przesuszona",
                "Wrażliwa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Konopny",
                "Masło Shea",
                "Hydromanil",
                "Kwas Hialuronowy",
                "Ekstrakt Z Awokado",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "D Panthenol",
                "Witamina E",
                "Witamina C",
                "Aloes"
            ]
        },
        {
            "id": 320,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [
                "Przesuszona",
                "Sucha",
                "Wrażliwa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Konopny",
                "Hydrolat",
                "Masło Shea",
                "Hydromanil",
                "Kwas Hialuronowy",
                "Ekstrakt Z Awokado",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Masło Shea",
                "Kwas Hialuronowy",
                "D Pantenol",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 322,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Wrażliwa",
                "Dojrzała",
                "Mieszana"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Sucha Odwodniona",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Kolagen",
                "Elastyna",
                "Masło Shea",
                "Kwas Hialuronowy",
                "Olej Arganowy",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Alg",
                "D Panthenol",
                "Ekstrakt Z Mimozy"
            ],
            "basic_active_ingredients": [
                "Żel Aloesowy",
                "Witamina E",
                "Witamina C",
                "D Pantenol",
                "Kwas Hialuronowy",
                "Kolagen"
            ]
        },
        {
            "id": 325,
            "body_parties": [
                "Zęby"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Szałwii",
                "Ekstrakt Z Rumianku",
                "Ekstrakt Z Arniki"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 326,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Przesuszona",
                "Odwodniona"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Utrata Jędrności",
                "Zmarszczki"
            ],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Kolagen",
                "Elastyna",
                "Masło Shea",
                "Kwas Hialuronowy",
                "Olej Arganowy",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Alg",
                "Olej Makadamia",
                "D Panthenol",
                "Ekstrakt Z Mimozy"
            ],
            "basic_active_ingredients": [
                "Kwasy Aha",
                "Kwas Hialuronowy",
                "Kolagen",
                "D Pantenol",
                "Aloes",
                "Masło Shea",
                "Olej Arganowy",
                "Elastyna"
            ]
        },
        {
            "id": 327,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [],
            "skin_problems": [
                "Łuszczyca",
                "Egzema",
                "Atopowe Zapalenie Skóry"
            ],
            "ingredients": [
                "Minerały Z Morza Martwego"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 328,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Ekstrakt Z Lawendy",
                "Ekstrakt Z Rumianku",
                "Ekstrakt Z Tymianku",
                "Ekstrakt Z Szałwii",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Kolagen",
                "Elastyna"
            ]
        },
        {
            "id": 330,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Olej Słonecznikowy",
                "Ekstrakt Z Bluszczu",
                "Ekstrakt Z Imbiru",
                "Ekstrakt Z Nagietka",
                "Kwasy Aha"
            ],
            "basic_active_ingredients": [
                "Kwasy Aha"
            ]
        },
        {
            "id": 331,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Ekstrakt Z Czerwonej Herbaty",
                "Ekstrakt Z Bluszczu",
                "Ekstrakt Z Imbiru",
                "Ekstrakt Z Nostrobika",
                "Ekstrakt Z Nagietka",
                "Olej Arganowy",
                "Masło Shea",
                "Kawa",
                "Ekstrakt Z Zielonej Herbaty"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Witamina C",
                "Masło Shea"
            ]
        },
        {
            "id": 332,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Ekstrakt Z Alg",
                "Ekstrakt Z Mimozy"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 333,
            "body_parties": [],
            "skin_types": [
                "Sucha",
                "Zniszczona"
            ],
            "skin_problems": [],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Proteiny Jedwabiu",
                "Kolagen",
                "Olej Arganowy",
                "Masło Shea",
                "Ekstrakt Z Nasion Lnu",
                "Elastyna",
                "Ekstrakt Z Korzenia Łopianu"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 337,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Ekstrakt Z Awokado",
                "Olej Arganowy",
                "Olej Makadamia",
                "Ceramidy",
                "Koenzym Q10",
                "Elastyna",
                "Kolagen",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Mimozy",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Kolagen",
                "Elastyna",
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Ceramidy"
            ]
        },
        {
            "id": 338,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Kwas Hialuronowy",
                "Ekstrakt Z Awokado",
                "Kolagen",
                "Elastyna",
                "Koenzym Q10",
                "Olej Arganowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna",
                "Koenzym Q10",
                "Witamina E",
                "Witamina C",
                "Olej Arganowy"
            ]
        },
        {
            "id": 340,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Przesuszona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Ekstrakt Z Żurawiny",
                "Olej Arganowy",
                "Masło Shea",
                "Ekstrakt Z Ryżu",
                "Aloes"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Witamina C",
                "Kolagen",
                "Elastyna",
                "Aloes"
            ]
        },
        {
            "id": 341,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Wrażliwa",
                "Naczynkowa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Żurawiny",
                "Ekstrakt Z Mimozy",
                "Kwasy Aha"
            ],
            "basic_active_ingredients": [
                "Kwas Mlekowy",
                "Kwas Winowy",
                "Kwasy Aha"
            ]
        },
        {
            "id": 342,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Żurawiny",
                "Ekstrakt Z Trawy Cytrynowej"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 343,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała",
                "Odwodniona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cera Dojrzała",
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Snap-8tm",
                "Koenzym Q10",
                "Cobiolift"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 352,
            "body_parties": [],
            "skin_types": [
                "Odwodniona",
                "Sucha",
                "Normalna"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Liofilizowane Maliny",
                "Hydromanil",
                "Kwas Hialuronowy",
                "Skwalan",
                "Olej Migdałowy",
                "Olej Z Pestek Malin",
                "D Panthenol",
                "Ceramidy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Skwalan",
                "Ceramidy",
                "D Panthenol"
            ]
        },
        {
            "id": 354,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Tłusta",
                "Mieszana",
                "Trądzikowa"
            ],
            "skin_problems": [
                "Cera Z Trądzikiem",
                "Problem Trądzik",
                "Problem Rozszerzone Pory"
            ],
            "ingredients": [
                "Aktywny Węgiel Z Bambusa",
                "Jonizowane Srebro",
                "Hydromanil",
                "Ekstrakt Z Kiwi"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 356,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Trądzikowa",
                "Wrażliwa",
                "Normalna"
            ],
            "skin_problems": [
                "Problem Trądzik",
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Ekstrakt Z Alg",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Awokado"
            ],
            "basic_active_ingredients": [
                "Witaminy",
                "Witamina E",
                "Witamina C",
                "Aloes"
            ]
        },
        {
            "id": 361,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Sucha",
                "Przesuszona",
                "Wrażliwa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Konopny",
                "Hydromanil",
                "Masło Shea",
                "Kwas Hialuronowy",
                "Ekstrakt Z Żeń Szenia"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Masło Shea",
                "D Pantenol",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 362,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Trądzikowa",
                "Tłusta",
                "Mieszana"
            ],
            "skin_problems": [
                "Problem Trądzik"
            ],
            "ingredients": [
                "Ekstrakt Z Alg",
                "Ekstrakt Z Mimozy",
                "Ekstrakt Z Aloesu",
                "Kwas Hialuronowy",
                "Ceramidy"
            ],
            "basic_active_ingredients": [
                "Witaminy",
                "Witamina E",
                "Witamina C",
                "Kwas Hialuronowy",
                "Ceramidy",
                "D Pantenol"
            ]
        },
        {
            "id": 365,
            "body_parties": [
                "Stopy"
            ],
            "skin_types": [
                "Sucha"
            ],
            "skin_problems": [],
            "ingredients": [
                "Jonizowane Srebro",
                "Olej Konopny",
                "Mocznik"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 366,
            "body_parties": [
                "Stopy"
            ],
            "skin_types": [
                "Sucha",
                "Normalna"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Zmarszczki"
            ],
            "ingredients": [
                "Ekstrakt Z Arniki",
                "Ekstrakt Z Lnu"
            ],
            "basic_active_ingredients": [
                "Mocznik"
            ]
        },
        {
            "id": 367,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Odwodniona",
                "Normalna",
                "Zmęczona",
                "Zniszczona"
            ],
            "skin_problems": [
                "Cera Dojrzała",
                "Cera Sucha Odwodniona",
                "Problem Zmarszczki"
            ],
            "ingredients": [
                "Ekstrakt Z Marakui",
                "Ekstrakt Z Mango",
                "Kwas Hialuronowy",
                "Masło Shea",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwas Hialuronowy",
                "Antyoksydanty"
            ]
        },
        {
            "id": 368,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Każdego rodzaju skóry"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Marakui",
                "Ekstrakt Z Mango",
                "Kwas Hialuronowy",
                "Masło Shea",
                "Olej Słonecznikowy",
                "Olej Migdałowy",
                "Olej Kokosowy"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 369,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Tłusta",
                "Mieszana",
                "Wrażliwa",
                "Odwodniona"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Ekstrakt Z Marakui",
                "Ekstrakt Z Mango",
                "Masło Shea",
                "Olej Słonecznikowy",
                "Ekstrakt Z Ananasa",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Witaminy",
                "Witamina E",
                "Witamina C",
                "Masło Shea",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 370,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Marakui",
                "Ekstrakt Z Mango"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Witaminy"
            ]
        },
        {
            "id": 371,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Przebarwienia",
                "Zmarszczki"
            ],
            "ingredients": [
                "Ekstrakt Z Marakui",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Ananasa",
                "Kwas Hialuronowy",
                "Masło Shea"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Masło Shea"
            ]
        },
        {
            "id": 381,
            "body_parties": [],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Snap-8tm",
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Olej Monoi",
                "Cobiolift"
            ],
            "basic_active_ingredients": [
                "Peptydy Biomimetyczne",
                "Koenzym Q10",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 382,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [
                "Dojrzała",
                "Odwodniona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Snap-8tm",
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Olej Monoi",
                "Olej Z Orzechów Makadamia",
                "Cobiolift"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Koenzym Q10",
                "Olej Monoi"
            ]
        },
        {
            "id": 389,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Odwodniona",
                "Mieszana"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Olej Migdałowy",
                "Olej Słonecznikowy",
                "Masło Shea",
                "Ekstrakt z Awokado",
                "Ekstrakt z Aloesu",
                "Ekstrakt Z Nasion Lnu",
                "Hydromanil"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 390,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Przesuszona",
                "Odwodniona"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Olej Migdałowy",
                "Olej Słonecznikowy",
                "Masło Shea",
                "Ekstrakt Z Awokado",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Nasion Lnu",
                "Kwas Hialuronowy",
                "Hydromanil"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Witamina C",
                "Witamina E",
                "Masło Shea",
                "Aloes"
            ]
        },
        {
            "id": 391,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Awokado",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Nasion Lnu",
                "Olej Słonecznikowy",
                "Olej Migdałowy",
                "Olej Kokosowy"
            ],
            "basic_active_ingredients": [
                "Aloes",
                "Witaminy",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 392,
            "body_parties": [
                "Ciało",
                "Dłonie"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Żeń Szenia",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Aloesu",
                "Kwas Hialuronowy",
                "Masło Shea",
                "Olej Słonecznikowy",
                "Ekstrakt Z Awokado"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Masło Shea",
                "Olej Słonecznikowy"
            ]
        },
        {
            "id": 395,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Tłusta",
                "Łojotokowa",
                "Trądzikowa"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Przebarwienia",
                "Trądzik Pospolity",
                "Cera Z Trądzikiem"
            ],
            "ingredients": [],
            "basic_active_ingredients": [
                "Kwasy Aha"
            ]
        },
        {
            "id": 396,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Trądzikowa",
                "Z Przebarwieniami",
                "Naczynkowa",
                "Łuszcząca"
            ],
            "skin_problems": [
                "Trądzik Różowaty",
                "Trądzik Pospolity",
                "Przebarwienia",
                "Cera Trądzikowa"
            ],
            "ingredients": [],
            "basic_active_ingredients": [
                "Kwas Azelainowy"
            ]
        },
        {
            "id": 397,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Trądzikowa",
                "Z Przebarwieniami"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Przebarwienia",
                "Trądzik Różowaty",
                "Trądzik Pospolity"
            ],
            "ingredients": [
                "Kwas Azelainowy",
                "Kwas Migdałowy",
                "Kwas Mlekowy",
                "Aqua"
            ],
            "basic_active_ingredients": [
                "Kwasy Aha",
                "Kwas Mlekowy"
            ]
        },
        {
            "id": 398,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Dojrzała",
                "Sucha",
                "Zmęczona",
                "Szara",
                "Z Przebarwieniami",
                "Trądzikowa"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Przebarwienia",
                "Blizny Potrądzikowe",
                "Cera Dojrzała",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [],
            "basic_active_ingredients": [
                "Kwas Ferulowy",
                "Antyoksydanty"
            ]
        },
        {
            "id": 399,
            "body_parties": [
                "Ciało",
                "Dłonie"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 402,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Ze Śliwek Kakadu",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Wiśni",
                "Ekstrakt Z Nasion Chia"
            ],
            "basic_active_ingredients": [
                "Witamina C"
            ]
        },
        {
            "id": 403,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Normalna",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Ekstrakt Z Alg",
                "Ekstrakt Z Arniki"
            ],
            "basic_active_ingredients": [
                "Peptydy",
                "D Pantenol",
                "Kwas Salicylowy"
            ]
        },
        {
            "id": 404,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Odwodniona"
            ],
            "skin_problems": [],
            "ingredients": [
                "Hydromanil",
                "Skwalan",
                "D Panthenol",
                "Ekstrakt Z Pestek Malin",
                "Ceramidy"
            ],
            "basic_active_ingredients": [
                "Komórki Macierzyste",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 405,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Zmęczona",
                "Szara",
                "Ziemista"
            ],
            "skin_problems": [
                "Cera Ziemista Zmęczona",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Aktywny Tlen",
                "Minerały Z Morza Martwego",
                "Ekstrakt Z Żeń Szenia",
                "Algi",
                "Ceramidy"
            ],
            "basic_active_ingredients": [
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Ceramidy"
            ]
        },
        {
            "id": 407,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa",
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cera Wrażliwa Naczynkowa",
                "Problem Zmarszczki"
            ],
            "ingredients": [
                "Ekstrakt ze Śliwek Kakadu",
                "Ekstrakt Z Wiśni",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Goji",
                "Nasiona Chia",
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna"
            ]
        },
        {
            "id": 409,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Szara",
                "Ziemista",
                "Naczynkowa",
                "Z Przebarwieniami"
            ],
            "skin_problems": [
                "Cera Dojrzała",
                "Przebarwienia",
                "Zmarszczki",
                "Cienie Obrzęki Pod Oczami",
                "Cera Ziemista Zmęczona",
                "Rozszerzone Pory"
            ],
            "ingredients": [
                "Olej Monoi",
                "Olej Abisyński",
                "Olej Awokado",
                "Masło Shea",
                "Kwas Hialuronowy",
                "D-Pantenol",
                "Ceramidy"
            ],
            "basic_active_ingredients": [
                "Retinol",
                "Witamina C",
                "Kwas Hialuronowy",
                "D Pantenol",
                "Ceramidy"
            ]
        },
        {
            "id": 411,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Tensup",
                "Masło Shea",
                "Olej Arganowy",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 412,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Odwodniona",
                "Zniszczona",
                "Łuszcząca",
                "Przesuszona"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Witamina A",
                "Witamina C",
                "Witamina B",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 414,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Szara",
                "Ziemista"
            ],
            "skin_problems": [
                "Problem Przebarwienia",
                "Zmarszczki"
            ],
            "ingredients": [
                "Ekstrakt Ze Śliwek Kakadu",
                "Ekstrakt Z Wiśni",
                "Ekstrakt Z Jagód Goji",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Nasion Chia",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 415,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Tłusta",
                "Trądzikowa",
                "Z Przebarwieniami",
                "Naczynkowa",
                "Dojrzała"
            ],
            "skin_problems": [
                "Problem Przebarwienia",
                "Problem Zmarszczki",
                "Trądzik Pospolity",
                "Nadmierny Łojotok",
                "Rozszerzone Pory",
                "Trądzik Różowaty"
            ],
            "ingredients": [
                "Olej Kukui"
            ],
            "basic_active_ingredients": [
                "Kwas Mlekowy",
                "Kwas Migdałowy",
                "Kwas Ferulowy",
                "Kwas Fitowy",
                "Kwas Pirogronowy"
            ]
        },
        {
            "id": 416,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Aloes",
                "D Panthenol",
                "Hydromanil",
                "Kwas Hialuronowy",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Żel Aloesowy",
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        },
        {
            "id": 417,
            "body_parties": [],
            "skin_types": [
                "Normalna",
                "Wrażliwa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Hydromanil",
                "Ekstrakt Z Aloesu",
                "D Panthenol",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol",
                "Aloes",
                "Witamina B"
            ]
        },
        {
            "id": 418,
            "body_parties": [
                "Ciało",
                "Twarz",
                "Włosy"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Hydromanil",
                "Ekstrakt Z Żeń Szenia",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Aloesu",
                "Kwas Hialuronowy",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "Aloes",
                "D Pantenol",
                "Kwas Hialuronowy",
                "Witamina B"
            ]
        },
        {
            "id": 421,
            "body_parties": [],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 422,
            "body_parties": [],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 435,
            "body_parties": [
                "Twarz",
                "Ciało",
                "Włosy"
            ],
            "skin_types": [],
            "skin_problems": [
                "Cera Trądzikowa",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Aloes"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Witamina E",
                "Witamina A",
                "Witamina B",
                "Kwas Foliowy",
                "Niacynamid"
            ]
        },
        {
            "id": 436,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Hydromanil",
                "Masło Shea",
                "Olej Awokado",
                "Olej Abisyński",
                "Olej Ryżowy",
                "Saccharomyces Lysate Extract",
                "Lactobacillus Rhamnosus"
            ],
            "basic_active_ingredients": [
                "Probiotyki",
                "Masło Shea",
                "Kwas Salicylowy",
                "Witamina C"
            ]
        },
        {
            "id": 437,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Hydromanil"
            ],
            "basic_active_ingredients": [
                "Witaminy",
                "Witamina E",
                "Witamina C",
                "Kwas Hialuronowy",
                "Masło Shea",
                "Probiotyki"
            ]
        },
        {
            "id": 440,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Odwodniona",
                "Zniszczona",
                "Łuszcząca",
                "Przesuszona"
            ],
            "skin_problems": [
                "Problem Odwodnienie",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Witamina A",
                "Witamina C",
                "Witamina B",
                "Kwas Hialuronowy",
                "Aloes"
            ]
        },
        {
            "id": 447,
            "body_parties": [
                "Włosy",
                
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Żeń Szenia",
                "Ekstrakt Z Owsa",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 448,
            "body_parties": [
                "Włosy"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Mocznik",
                "Resplanta",
                "Ekstrakt Z Mięty",
                "Ekstrakt Z Żeń Szenia"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 449,
            "body_parties": [
                "Włosy",

            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Olej Z Pestek Malin",
                "Olej Z Baobabu",
                "Olej Migdałowy",
                "Olej Kukui",
                "Ekstrakt Z Żeń Szenia",
                "Ekstrakt Z Owsa",
                "Resplanta"
            ],
            "basic_active_ingredients": [
                "Resplanta",
                "Peptydy",
                "Komórki Macierzyste"
            ]
        },
        {
            "id": 450,
            "body_parties": [
                "Włosy",
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Olej Kokosowy"
            ],
            "basic_active_ingredients": [
                "Pantenol"
            ]
        },
        {
            "id": 451,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Szara",
                "Ziemista",
                "Naczynkowa",
                "Z Przebarwieniami"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Przebarwienia",
                "Rozszerzone Pory"
            ],
            "ingredients": [
                "Olej Monoi",
                "Olej Abisyński",
                "Masło Shea",
                "Ceramidy",
                "Retinol"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Witamina E",
                "Kwas Hialuronowy",
                "Ceramidy",
                "Spf15"
            ]
        },
        {
            "id": 452,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [
                "Odwodniona"
            ],
            "skin_problems": [
                "Problem Zmarszczki"
            ],
            "ingredients": [
                "Snap-8tm",
                "Olej Monoi",
                "Cobiolift"
            ],
            "basic_active_ingredients": [
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Skwalan"
            ]
        },
        {
            "id": 453,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Odwodniona",
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Problem Odwodnienie",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Snap-8tm",
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Olej Monoi",
                "Olej Z Orzechów Makadamia",
                "Cobiolift"
            ],
            "basic_active_ingredients": [
                "Skwalan",
                "Kwas Hialuronowy",
                "Koenzym Q10",
                "Kwas Salicylowy",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 455,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Zmęczona",
                "Ziemista",
                "Sucha",
                "Odwodniona",
                "Szara"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cera Ziemista Zmęczona",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Aktywny Tlen",
                "Kwas Hialuronowy",
                "Koenzym Q10",
                "Ekstrakt Z Żeń Szenia"
            ],
            "basic_active_ingredients": [
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Ceramidy"
            ]
        },
        {
            "id": 456,
            "body_parties": [],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 457,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Odwodniona"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Liofilizowane Maliny",
                "Komórki Macierzyste z Borówki Brusznicy",
                "Ceramidy",
                "Kwas Hialuronowy",
                "Hydromanil",
                "Skwalan",
                "Olej Migdałowy",
                "Olej Z Pestek Malin",
                "Alginat"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Ceramidy",
                "Skwalan",
                "D Panthenol"
            ]
        },
        {
            "id": 459,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Tłusta",
                "Mieszana",
                "Trądzikowa"
            ],
            "skin_problems": [
                "Trądzik Pospolity",
                "Rozszerzone Pory",
                "Nadmierny Łojotok"
            ],
            "ingredients": [
                "Aktywny Węgiel Z Bambusa",
                "Jonizowane Srebro",
                "Ekstrakt z Kiwi",
                "Ekstrakt z Zielonej Herbaty",
                "Hydromanil"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 460,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Ekstrakt Ze Śliwek Kakadu",
                "Ekstrakt Z Wiśni",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Nasion Chia",
                "Algi"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Antyoksydanty"
            ]
        },
        {
            "id": 461,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Trądzikowa",
                "Mieszana",
                "Tłusta"
            ],
            "skin_problems": [
                "Cera Z Trądzikiem",
                "Problem Trądzik",
                "Problem Rozszerzone Pory"
            ],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Alg",
                "Ekstrakt Z Bambusa"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 472,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Naczynkowa",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa",
                "Rozszerzone Pory"
            ],
            "ingredients": [
                "Ekstrakt Z Dzikiej Róży",
                "Ekstrakt Z Arniki"
            ],
            "basic_active_ingredients": [
                "Witamina C"
            ]
        },
        {
            "id": 477,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Naczynkowa",
                "Wrażliwa",
                "Sucha",
                "Ziemista"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa",
                "Cera Sucha Odwodniona",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Ekstrakt Z Białych Winogron",
                "Ekstrakt Z Marakui",
                "Algi"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwas Hialuronowy",
                "Elastyna"
            ]
        },
        {
            "id": 478,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [],
            "skin_problems": [
                "Problem Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Linefill",
                "Ekstrakt z Orchidei",
                "Ekstrakt z Rooibosa",
                "Algi",
                "Kwas Hialuronowy",
                "Skwalan z Oliwek"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Pantenol"
            ]
        },
        {
            "id": 479,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Utrata Jędrności",
                "Zmarszczki",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Tensup",
                "Hydromanil"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 480,
            "body_parties": [
                "Włosy"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Resplanta",
                "Hydromanil",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Witamina A",
                "Witamina E",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 481,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Z Przebarwieniami"
            ],
            "skin_problems": [
                "Przebarwienia"
            ],
            "ingredients": [
                "Melavoid",
                "Darkout",
                "Ekstrakt Z Grejpfruta",
                "Ekstrakt Z Ogórka",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Witamina B",
                "Witamina C",
                "Witamina E"
            ]
        },
        {
            "id": 483,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała",
                "Zmęczona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Hydrolat Z Kokosa",
                "Komórki Macierzyste",
                "Peptydy",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Peptydy",
                "Komórki Macierzyste",
                "Kwas Hialuronowy",
                "Witaminy"
            ]
        },
        {
            "id": 484,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Komórki Macierzyste",
                "Ekstrakt Z Alg",
                "Olej Kukui"
            ],
            "basic_active_ingredients": [
                "Komórki Macierzyste",
                "Kwas Fitowy",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 485,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała",
                "Przesuszona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Sucha Odwodniona",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Ekstrakt Z Alg",
                "Kwas Hialuronowy",
                "Komórki Macierzyste",
                "Olej Marula",
                "Woda Kokosowa"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Komórki Macierzyste",
                "Peptydy",
                "Witamina E"
            ]
        },
        {
            "id": 486,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Alg",
                "Ekstrakt Z Nagietka",
                "Guarana",
                "Olej Kokosowy"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 487,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [],
            "skin_problems": [
                "Cera Ziemista Zmęczona",
                "Przebarwienia",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Guarana",
                "Ekstrakt Z Kokosa",
                "Ekstrakt Z Alg",
                "Olej Migdałowy",
                "Olej Kokosowy"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 489,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała",
                "Odwodniona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Dojrzała",
                "Problem Zmarszczki",
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Snap-8tm",
                "Cobiolift",
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Olej Monoi"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Skwalan",
                "Koenzym Q10"
            ]
        },
        {
            "id": 490,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Utrata Jędrności",
                "Cera Sucha Odwodniona",
                "Cera Ziemista Zmęczona",
                "Przebarwienia"
            ],
            "ingredients": [
                "Ekstrakt Z Ananasa",
                "Ekstrakt Z Kokosa",
                "Guarana",
                "Ekstrakt Z Alg"
            ],
            "basic_active_ingredients": [
                "Witaminy",
                "Antyoksydanty"
            ]
        },
        {
            "id": 493,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna",
                "Z Przebarwieniami"
            ],
            "skin_problems": [
                "Przebarwienia",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Melavoid",
                "Darkout",
                "Ekstrakt Z Ogórka",
                "Ekstrakt Z Grejpfruta",
                "Proteiny Jedwabiu",
                "Kwas Hialuronowy",
                "Ceramidy",
                "Olej Kokosowy"
            ],
            "basic_active_ingredients": [
                "Witamina B",
                "Witamina C",
                "Witamina E",
                "Skwalan",
                "D Panthenol"
            ]
        },
        {
            "id": 494,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna",
                "Z Przebarwieniami"
            ],
            "skin_problems": [
                "Przebarwienia",
                "Problem Przebarwienia"
            ],
            "ingredients": [
                "Melavoid",
                "Darkout",
                "Ekstrakt Z Grejpfruta",
                "Ekstrakt Z Ogórka",
                "Olej Kokosowy",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "Witamina B",
                "Witamina C",
                "Witamina E",
                "D Panthenol"
            ]
        },
        {
            "id": 495,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Arbuza",
                "Ekstrakt Z Melona",
                "Kwas Hialuronowy",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 498,
            "body_parties": [
                "Twarz",
                "Ciało",
                "Włosy"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Ze Smoczego Owocu"
            ],
            "basic_active_ingredients": [
                "Żel Aloesowy",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 499,
            "body_parties": [
                "Ciało",
                "Twarz",
                "Włosy"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Hydromanil",
                "Ekstrakt Z Żeń Szenia",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        },
        {
            "id": 500,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Szara",
                "Ziemista"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Ze Śliwek Kakadu",
                "Ekstrakt Z Wiśni",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Goji",
                "Olej Z Nasion Chia",
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwas Hialuronowy",
                "Kolagen"
            ]
        },
        {
            "id": 501,
            "body_parties": [],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 503,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Odwodniona"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Arbuza",
                "Ekstrakt Z Mango",
                "Olej Arganowy",
                "Olej Z Pestek Arbuza",
                "Olej Słonecznikowy",
                "Ekstrakt Z Alg",
                "Masło Shea",
                "Witamina E",
                "Kwas Askorbinowy"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Kwas Askorbinowy",
                "Masło Shea"
            ]
        },
        {
            "id": 505,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Arbuza"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 506,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Z Pestek Arbuza",
                "Masło Shea",
                "Witamina E",
                "Kwas Hialuronowy",
                "Ekstrakt Z Melona",
                "Kwas Askorbinowy"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Kwas Hialuronowy",
                "Kwas Askorbinowy",
                "Masło Shea"
            ]
        },
        {
            "id": 507,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Wrażliwa"
            ],
            "skin_problems": [
                "Trądzik Różowaty",
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Agascalm",
                "Ekstrakt Z Grzybów Chaga",
                "Ekstrakt Z Czerwonej Algi Koralowej",
                "Ekstrakt Z Arniki",
                "Ekstrakt Z Marakui",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Kwas Azelainowy",
                "Witamina E",
                "D Panthenol"
            ]
        },
        {
            "id": 508,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Wrażliwa"
            ],
            "skin_problems": [
                "Trądzik Różowaty",
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Agascalm",
                "Ekstrakt Z Czerwonej Algi",
                "Olej Z Marakui",
                "Olej Słonecznikowy",
                "D Pantenol",
                "Ekstrakt Z Arniki"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 509,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Trądzikowa",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Trądzik Różowaty",
                "Podrażnienia",
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Hydrolat z Rumianku",
                "Agascalm",
                "Ekstrakt Z Czerwonej Herbaty",
                "D Pantenol",
                "Ekstrakt Z Arniki"
            ],
            "basic_active_ingredients": [
                "D Pantenol"
            ]
        },
        {
            "id": 510,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Zmęczona",
                "Szara",
                "Ziemista",
                "Odwodniona"
            ],
            "skin_problems": [
                "Cera Ziemista Zmęczona",
                "Cera Sucha Odwodniona",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Aktywny Tlen",
                "Kwas Hialuronowy",
                "Koenzym Q10",
                "Ekstrakt Z Żeń Szenia",
                "Olej Baobabu",
                "Olej Makadamia",
                "Olej Słonecznikowy",
                "Ceramidy"
            ],
            "basic_active_ingredients": [
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Witamina E",
                "Ceramidy"
            ]
        },
        {
            "id": 511,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Zmęczona",
                "Szara",
                "Ziemista"
            ],
            "skin_problems": [
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Aktywny Tlen",
                "Kolagen",
                "Elastyna",
                "Kwas Hialuronowy",
                "Ekstrakt Z Żeń Szenia",
                "Olej Z Baobabu"
            ],
            "basic_active_ingredients": [
                "Aktywny Tlen",
                "Kolagen",
                "Elastyna",
                "Kwas Hialuronowy",
                "Koenzym Q10",
                "Ceramidy"
            ]
        },
        {
            "id": 512,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Wrażliwa"
            ],
            "skin_problems": [
                "Trądzik Różowaty",
                "Cera Wrażliwa Naczynkowa",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Agascalm",
                "Ekstrakt Z Czerwonej Algi",
                "Ekstrakt Z Arniki",
                "Olej Z Marakui"
            ],
            "basic_active_ingredients": [
                "D Panthenol",
                "Kwas Hialuronowy",
                "Witamina E"
            ]
        },
        {
            "id": 513,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Normalna",
                "Trądzikowa",
                "Zmęczona",
                "Zniszczona"
            ],
            "skin_problems": [
                "Cera Z Trądzikiem",
                "Trądzik Pospolity",
                "Trądzik Różowaty",
                "Problem Trądzik",
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Ekstrakt Z Papai",
                "Ekstrakt Z Ananasa"
            ],
            "basic_active_ingredients": [
                "Witamina A",
                "Witamina C"
            ]
        },
        {
            "id": 521,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 524,
            "body_parties": [
                "Ciało",
                "Dłonie"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Olej Z Marakui",
                "Olej Z Pestek Śliwki",
                "Olej Słonecznikowy",
                "Hydromanil",
                "Ekstrakt Z Aceroli",
                "Ekstrakt Z Winogron",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 525,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Zmęczona"
            ],
            "skin_problems": [
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Olej Z Marakui",
                "Olej Słonecznikowy",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Daktyli",
                "Ekstrakt Z Opuncji Figowej"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 526,
            "body_parties": [
                "Stopy"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Olej Słonecznikowy",
                "Olej Arganowy",
                "Olej Kokosowy",
                "Masło Shea",
                "Skwalan",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Owsa",
                "Ekstrakt Z Aloesu",
                "D Panthenol",
                "Kwas Hialuronowy",
                "Witamina A",
                "Witamina E"
            ],
            "basic_active_ingredients": [
                "Skwalan",
                "Witamina A",
                "Witamina E",
                "Kwas Hialuronowy",
                "D Pantenol",
                "Aloes",
                "Masło Shea"
            ]
        },
        {
            "id": 528,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Trądzikowa",
                "Mieszana",
                "Tłusta"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Rozszerzone Pory",
                "Nadmierny Łojotok",
                "Cera Z Trądzikiem",
                "Cera Mieszana",
                "Cera Tłusta"
            ],
            "ingredients": [
                "Minerały Z Morza Martwego",
                "Ekstrakt Z Zielonej Herbaty",
                "Ekstrakt Z Algi",
                "Aktywny Węgiel Z Bambusa"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 530,
            "body_parties": [
                "Twarz",
                "Oczy",
                "Usta"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Migdałowy",
                "Olej Abisyński",
                "Olej Winogronowy",
                "Olej Konopny",
                "Olej Z Awokado",
                "Olej Z Dyni"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "D Panthenol",
                "Kwas Salicylowy"
            ]
        },
        {
            "id": 531,
            "body_parties": [
                "Twarz",
                "Oczy"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Z Mango",
                "Olej Z Marakui",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 532,
            "body_parties": [
                "Twarz",
                "Oczy"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Masło Kakaowe",
                "Masło Shea",
                "Olej Migdałowy",
                "Olej Konopny",
                "Olej Babassu",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "D Pantenol",
                "Masło Shea",
                "Kwas Salicylowy"
            ]
        },
        {
            "id": 535,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Olej Migdałowy",
                "Olej Z Pestek Słonecznika",
                "Olej Arganowy",
                "Ekstrakt Z Miodu",
                "Mleczko Pszczele"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Kwas Askorbinowy",
                "Karoten"
            ]
        },
        {
            "id": 536,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Kurkumy",
                "Ekstrakt Z Miodu"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 537,
            "body_parties": [],
            "skin_types": [
                "Wrażliwa",
                "Sucha",
                "Mieszana",
                "Ziemista"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Cera Wrażliwa Naczynkowa",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Ekstrakt Z Miodu",
                "Ekstrakt Z Kurkumy",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Kwas Askorbinowy",
                "Witamina E"
            ]
        },
        {
            "id": 538,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa",
                "Ziemista"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Przebarwienia",
                "Cera Ziemista Zmęczona",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Olej Słonecznikowy",
                "Ekstrakt Z Kurkumy",
                "Ekstrakt Z Miodu"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Witamina A",
                "Kwas Askorbinowy"
            ]
        },
        {
            "id": 542,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała",
                "Odwodniona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Problem Zmarszczki"
            ],
            "ingredients": [
                "Cobiolift",
                "SNAP-8tm",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Peptydy Biomimetyczne"
            ]
        },
        {
            "id": 566,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Tłusta",
                "Odwodniona",
                "Trądzikowa"
            ],
            "skin_problems": [
                "Problem Trądzik",
                "Problem Odwodnienie"
            ],
            "ingredients": [
                "Hydrolat Z Zielonej Herbaty",
                "Ekstrakt Z Pigwy",
                "Ekstrakt Z Mirtu Cytrynowego",
                "Aloes"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 573,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Opuncji Figowej"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 574,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Lotosu",
                "Ekstrakt Z Wiśni"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 575,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Malin",
                "Ekstrakt Z Granatu",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 576,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Marakui"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 577,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Goji",
                "Ekstrakt Z Malin",
                "Ekstrakt Z Borówki",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 585,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [
                "Sucha",
                "Odwodniona"
            ],
            "skin_problems": [
                "Problem Odwodnienie",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Hydromanil",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Daktyli",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 586,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Olej Z Awokado",
                "Olej Z Winogron",
                "Olej Słonecznikowy",
                "Ekstrakt Z Marakui",
                "Ekstrakt Z Orchidei",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 587,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Blizny Potrądzikowe",
                "Cera Mieszana"
            ],
            "ingredients": [
                "Kwas Glikolowy"
            ],
            "basic_active_ingredients": [
                "Kwas Glikolowy"
            ]
        },
        {
            "id": 591,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Masło Kakaowe",
                "Karoten",
                "Olej Arganowy",
                "Ekstrakt Z Aloesu",
                "D Pantenol"
            ],
            "basic_active_ingredients": [
                "Spf30",
                "Karoten",
                "Aloes",
                "D Pantenol"
            ]
        },
        {
            "id": 592,
            "body_parties": [],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Nektar Komórkowy",
                "Olej Arganowy",
                "Olej Monoi",
                "Olej Z Pestek Malin",
                "Karoten"
            ],
            "basic_active_ingredients": [
                "Spf30",
                "Komórki Macierzyste",
                "Kwasy Fitowy",
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        },
        {
            "id": 593,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Nektar Komórkowy",
                "Olej Arganowy",
                "Olej Monoi",
                "Olej Z Pestek Malin",
                "Karoten"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol",
                "Spf50"
            ]
        },
        {
            "id": 594,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Słonecznikowy",
                "Ekstrakt Z Daktyli",
                "Olej Makadamia"
            ],
            "basic_active_ingredients": [
                "Spf20",
                "Karoten"
            ]
        },
        {
            "id": 595,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Woda Pomarańczowa",
                "Nektar Komórkowy",
                "Sok Z Miąższu Liści Aloesu",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Spf15",
                "Komórki macierzyste",
                "Kwas Hialuronowy",
                "Aloes"
            ]
        },
        {
            "id": 600,
            "body_parties": [],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Słonecznikowy",
                "Olej Z Pestek Winogron",
                "Masło Shea",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Spf30",
                "Kwas Hialuronowy",
                "Witamina E",
                "D Panthenol"
            ]
        },
        {
            "id": 601,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna",
                "Mieszana"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Rozszerzone Pory"
            ],
            "ingredients": [
                "Ekstrakt Z Banana",
                "Ekstrakt Z Opuncji Figowej",
                "Ekstrakt Z Pigwy",
                "Olej Migdałowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Witamina E"
            ]
        },
        {
            "id": 603,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Granatu",
                "Ekstrakt Z Malin",
                "Hydromanil",
                "Masło Shea",
                "Olej Z Pestek Malin"
            ],
            "basic_active_ingredients": [
                "Witamina E"
            ]
        },
        {
            "id": 604,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Szara",
                "Zmęczona"
            ],
            "skin_problems": [],
            "ingredients": [
                "Masło Shea",
                "Hydromanil",
                "Ekstrakt Z Marakui",
                "Ekstrakt Z Mandarynek",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Witamina E"
            ]
        },
        {
            "id": 612,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Hydromanil",
                "Ekstrakt Z Aloesu",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "D Pantenol"
            ]
        },
        {
            "id": 616,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Kwas Hialuronowy",
                "Ekstrakt Z Mięty",
                "Ekstrakt Z Limonki"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Witamina C"
            ]
        },
        {
            "id": 617,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Yuzu",
                "Ekstrakt Z Kardamonu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Witamina C"
            ]
        },
        {
            "id": 638,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [
                "Zmęczona",
                "Dojrzała"
            ],
            "skin_problems": [
                "Cienie Obrzęki Pod Oczami",
                "Problem Zmarszczki"
            ],
            "ingredients": [
                "Ekstrakt Ze Śnieżnej Algi",
                "Ekstrakt Z Guarany",
                "Kwas Hialuronowy",
                "Eye Fective"
            ],
            "basic_active_ingredients": [
                "Kofeina",
                "Kwas Hialuronowy",
                "Ceramidy"
            ]
        },
        {
            "id": 639,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cienie Obrzęki Pod Oczami"
            ],
            "ingredients": [
                "Szwajcarskie Mrożone Wino",
                "Ekstrakt Z Czerwonych Alg",
                "Adaptogen Z Grzybów Chaga"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 640,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cera Dojrzała",
                "Problem Zmarszczki",
                "Utrata Jędrności",
                "Przebarwienia"
            ],
            "ingredients": [
                "Koloidalna Platyna",
                "Peptyd Miedziowy",
                "Kwas Hialuronowy",
                "Witamina B",
                "Witamina E",
                "Masło Shea",
                "Olej Z Nasion Żmijowca",
                "Niacynamid",
                "Skwalan"
            ],
            "basic_active_ingredients": [
                "Niacynamid",
                "Skwalan",
                "Peptyd Miedziowy",
                "Kwas Hialuronowy",
                "Witamina B",
                "Witamina E",
                "Masło Shea"
            ]
        },
        {
            "id": 641,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Przebarwienia",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Koloidalna Platyna",
                "Peptyd Miedziowy",
                "Hydrolat",
                "Niacynamid",
                "Skwalan",
                "Bio-Propanediol"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Witamina B",
                "Skwalan"
            ]
        },
        {
            "id": 642,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Przebarwienia"
            ],
            "ingredients": [
                "Koloidalna Platyna",
                "Peptyd Miedziowy",
                "Kwas Hialuronowy",
                "Niacynamid",
                "Witamina B"
            ],
            "basic_active_ingredients": [
                "Peptyd Miedziowy",
                "Niacynamid",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 643,
            "body_parties": [],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Cienie Obrzęki Pod Oczami",
                "Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Koloidalna Platyna",
                "Peptyd Miedziowy",
                "Kwas Hialuronowy",
                "Witamina B",
                "Koenzym Q10",
                "Olej Słonecznikowy",
                "Masło Shea",
                "Olej Z Nasion Żmijowca"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Witamina B",
                "Koenzym Q10",
                "Peptyd Miedziowy",
                "Masło Shea"
            ]
        },
        {
            "id": 644,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Przebarwienia"
            ],
            "ingredients": [
                "Koloidalna Platyna",
                "Peptyd Miedziowy",
                "Niacynamid",
                "Koenzym Q10"
            ],
            "basic_active_ingredients": [
                "Niacynamid",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 645,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Wrażliwa",
                "Sucha"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Ashwagandy",
                "D Panthenol"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 646,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Odwodniona"
            ],
            "skin_problems": [],
            "ingredients": [
                "Woda Z Lodowca Mont Blanc",
                "Wyciąg Z Bakterii Lodowca IceAwake™",
                "Olej Z Nasion Meadowfoam",
                "D-Panthenol",
                "Kwas Hialuronowy",
                "Resplanta Z Babassu",
                "Skwalan",
                "Bio-Propanediol"
            ],
            "basic_active_ingredients": [
                "D Pantenol",
                "Skwalan",
                "Witamina E",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 647,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Sucha",
                "Odwodniona"
            ],
            "skin_problems": [
                "Cera Ziemista Zmęczona",
                "Zmarszczki"
            ],
            "ingredients": [],
            "basic_active_ingredients": [
                "D Panthenol",
                "Kwas Salicylowy"
            ]
        },
        {
            "id": 648,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Wrażliwa",
                "Sucha"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Konopny",
                "Ekstrakt Z Ashwagandy",
                "Olej Arganowy",
                "D Panthenol",
                "Kwas Hialuronowy",
                "Skwalan",
                "Witamina E"
            ],
            "basic_active_ingredients": [
                "D Pantenol",
                "Kwas Hialuronowy",
                "Skwalan",
                "Witamina E"
            ]
        },
        {
            "id": 650,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Szara",
                "Zmęczona",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Ziemista Zmęczona",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Olej Brzoskwiniowy",
                "Masło Shea",
                "Karoten",
                "Hydrolat Z Neroli",
                "Ekstrakt Z Marakui"
            ],
            "basic_active_ingredients": [
                "Skwalan",
                "D Pantenol",
                "Kwas Salicylowy",
                "Ceramidy"
            ]
        },
        {
            "id": 651,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Szara",
                "Zmęczona",
                "Wrażliwa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Woda Z Neroli",
                "Ekstrakt Z Marakui"
            ],
            "basic_active_ingredients": [
                "Karoten",
                "Witamina E",
                "Skwalan",
                "Kwas Hialuronowy",
                "Ceramidy"
            ]
        },
        {
            "id": 652,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Szara",
                "Zmęczona",
                "Wrażliwa"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Zmarszczki",
                "Cera Ziemista Zmęczona",
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Ekstrakt Z Marakui",
                "Hydrolat Z Neroli"
            ],
            "basic_active_ingredients": [
                "Ceramidy",
                "Kwas Hialuronowy",
                "Skwalan",
                "Witaminy"
            ]
        },
        {
            "id": 653,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Szara",
                "Zmęczona",
                "Wrażliwa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Hydrolat Z Neroli"
            ],
            "basic_active_ingredients": [
                "Kwasy Aha",
                "Kwas Mlekowy",
                "Kwas Winowy",
                "Kwas Jabłkowy"
            ]
        },
        {
            "id": 673,
            "body_parties": [
                "Ciało",
                "Dłonie"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 679,
            "body_parties": [],
            "skin_types": [
                "Szara",
                "Zmęczona",
                "Z Przebarwieniami"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Przebarwienia",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Ekstrakt Z Kwiatów Maku",
                "Ekstrakt Z Orchidei",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Kwas Kawowy"
            ]
        },
        {
            "id": 680,
            "body_parties": [],
            "skin_types": [
                "Szara",
                "Zmęczona",
                "Z Przebarwieniami",
                "Dojrzała"
            ],
            "skin_problems": [
                "Przebarwienia",
                "Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Kwas Kawowy",
                "Ekstrakt Z Kwiatów Maku",
                "Kwas Hialuronowy",
                "Olej Jojoba",
                "Olej Z Nasion Kawy",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Pantenol",
                "Witamina E"
            ]
        },
        {
            "id": 681,
            "body_parties": [],
            "skin_types": [
                "Szara",
                "Zmęczona",
                "Z Przebarwieniami"
            ],
            "skin_problems": [
                "Cera Ziemista Zmęczona",
                "Przebarwienia",
                "Problem Przebarwienia",
                "Problem Zmarszczki"
            ],
            "ingredients": [
                "Ekstrakt Z Kwiatów Maku"
            ],
            "basic_active_ingredients": [
                "Kwas Kawowy",
                "Kwas Azelainowy",
                "Kwas Mlekowy",
                "Witamina E",
                "Kwas Salicylowy"
            ]
        },
        {
            "id": 682,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [
                "Zmęczona",
                "Odwodniona",
                "Dojrzała"
            ],
            "skin_problems": [
                "Cienie Obrzęki Pod Oczami",
                "Cera Dojrzała",
                "Problem Zmarszczki"
            ],
            "ingredients": [
                "Olej Z Nasion Kawy",
                "Olej Jojoba",
                "Ekstrakt Z Kwiatów Maku",
                "Kwas Hialuronowy",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Witamina A",
                "Kwas Hialuronowy",
                "Retinol"
            ]
        },
        {
            "id": 683,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [
                "Zmęczona"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cienie Obrzęki Pod Oczami"
            ],
            "ingredients": [
                "Olej Z Nasion Kawy",
                "Olej Jojoba",
                "Koenzym Q10",
                "Ekstrakt Z Kwiatów Maku",
                "Kwas Hialuronowy",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Kwas Kawowy",
                "Kwas Hialuronowy",
                "Koenzym Q10"
            ]
        },
        {
            "id": 685,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Zmęczona",
                "Z Przebarwieniami"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Przebarwienia",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Kwas Kawowy",
                "Olej Z Nasion Kawy",
                "Olej Jojoba",
                "Ekstrakt Z Kwiatów Maku",
                "Kwas Hialuronowy",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Kwas Kawowy",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 686,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Odwodniona",
                "Mieszana",
                "Łojotokowa"
            ],
            "skin_problems": [
                "Cera Mieszana",
                "Nadmierny Łojotok",
                "Cera Z Trądzikiem"
            ],
            "ingredients": [
                "Ekstrakt Z Mirtu Cytrynowego"
            ],
            "basic_active_ingredients": [
                "Niacynamid",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 687,
            "body_parties": [],
            "skin_types": [],
            "skin_problems": [
                "Przebarwienia",
                "Zmarszczki"
            ],
            "ingredients": [
                "Witamina C",
                "Ekstrakt Z Pigwy",
                "Kwas Hialuronowy",
                "Kwas Salicylowy"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwas Hialuronowy",
                "Kwas Salicylowy"
            ]
        },
        {
            "id": 690,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 694,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [],
            "skin_problems": [
                "Przesuszenie",
                "Podrażnienia"
            ],
            "ingredients": [
                "Olej Kokosowy",
                "Ekstrakt Z Kokosa",
                "Ekstrakt Z Lnu",
                "Masło Shea",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Pantenol"
            ]
        },
        {
            "id": 695,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [
                "Sucha",
                "Zniszczona"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Brzoskwiniowy",
                "Olej Migdałowy",
                "Olej Słonecznikowy",
                "Masło Shea",
                "Kwas Hialuronowy",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Nasion Lnu",
                "D Pantenol"
            ],
            "basic_active_ingredients": [
                "Witamina A",
                "Witamina E",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 708,
            "body_parties": [
                "Twarz",
                "Włosy"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Olej Migdałowy",
                "Olej Słonecznikowy",
                "Olej Makadamia",
                "Ekstrakt Z Yerba Mate",
                "Ekstrakt Z Jodły"
            ],
            "basic_active_ingredients": [
                "Aloes",
                "Witamina E"
            ]
        },
        {
            "id": 709,
            "body_parties": [
                "Twarz",
                "Włosy",
            
            ],
            "skin_types": [
                "Wrażliwa",
                "Przesuszona",
                "Zniszczona"
            ],
            "skin_problems": [
                "Podrażnienia",
                "Stany zapalne",
                "Przesuszenie"
            ],
            "ingredients": [
                "D Panthenol",
                "Ekstrakt Z Jodły",
                "Ekstrakt Z Yerba Mate"
            ],
            "basic_active_ingredients": [
                "D Panthenol"
            ]
        },
        {
            "id": 720,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Szara",
                "Zmęczona",
                "Z Przebarwieniami"
            ],
            "skin_problems": [
                "Cera Dojrzała",
                "Problem Przebarwienia",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Kwas Kawowy",
                "Ekstrakt Z Kwiatów Maku",
                "D Panthenol",
                "Algi"
            ],
            "basic_active_ingredients": [
                "Kwas Kawowy",
                "D Panthenol"
            ]
        },
        {
            "id": 721,
            "body_parties": [],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Przebarwienia"
            ],
            "ingredients": [
                "Koloidalna Platyna",
                "Peptyd Miedziowy",
                "Niacynamid"
            ],
            "basic_active_ingredients": [
                "Peptyd Miedziowy",
                "Niacynamid"
            ]
        },
        {
            "id": 737,
            "body_parties": [],
            "skin_types": [
                "Szara",
                "Ziemista",
                "Naczynkowa",
                "Z Przebarwieniami",
                "Normalna"
            ],
            "skin_problems": [
                "Cera Ziemista Zmęczona",
                "Przebarwienia",
                "Zmarszczki",
                "Rozszerzone Pory",
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 739,
            "body_parties": [],
            "skin_types": [],
            "skin_problems": [
                "Problem Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Kolagen Roślinny",
                "Kwasy Hialuronowy",
                "Ekstrakt Z Koniczyny",
                "Olej Jojoba",
                "Niacynamid"
            ],
            "basic_active_ingredients": [
                "Kolagen Roślinny",
                "Niacynamid",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 740,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Problem Zmarszczki",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Kolagen Roślinny",
                "Olej Z Nasion Żmijowca",
                "Ekstrakt Z Granatu",
                "Kwas Hialuronowy",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "Kolagen Roślinny",
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        },
        {
            "id": 741,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Problem Zmarszczki",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Kolagen Roślinny",
                "Olej Bakuchi",
                "Olej Słonecznikowy",
                "Ekstrakt Z Czarnego Tulipana",
                "Masło Shea",
                "Koenzym Q10",
                "Kwas Hialuronowy",
                "Olej Z Awokado",
                "Witamina E"
            ],
            "basic_active_ingredients": [
                "Kolagen Roślinny",
                "Olej Bakuchi",
                "Masło Shea",
                "Witamina E",
                "Kwas Hialuronowy",
                "Koenzym Q10"
            ]
        },
        {
            "id": 742,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cienie Obrzęki Pod Oczami"
            ],
            "ingredients": [
                "Ekstrakt Z Rumianku",
                "Ekstrakt Ze Świetlika",
                "Olej Jojoba",
                "Fitokolagen"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Skwalan",
                "Masło Shea",
                "Witamina E",
                "Kolagen Roślinny"
            ]
        },
        {
            "id": 743,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cienie Obrzęki Pod Oczami"
            ],
            "ingredients": [
                "Ekstrakt Z Czarnej Herbaty",
                "Ekstrakt Ze Świetlika",
                "Olej Z Nasion Żmijowca",
                "Skwalan",
                "Kwas Hialuronowy",
                "Masło Shea",
                "Koenzym Q10"
            ],
            "basic_active_ingredients": [
                "Kolagen Roślinny"
            ]
        },
        {
            "id": 744,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Przebarwienia",
                "Cera Ziemista Zmęczona",
                "Cienie Obrzęki Pod Oczami",
                "Problem Zmarszczki",
                "Problem Przebarwienia"
            ],
            "ingredients": [
                "Kolagen Roślinny",
                "Ekstrakt Z Kurkumy",
                "Olej Bakuchi",
                "Ekstrakt Ze Świetlika",
                "Olej Z Nasion Żmijowca"
            ],
            "basic_active_ingredients": [
                "Skwalan",
                "Kwas Hialuronowy",
                "Masło Shea",
                "Koenzym Q10"
            ]
        },
        {
            "id": 745,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Wrażliwa",
                "Z Przebarwieniami"
            ],
            "skin_problems": [
                "Przebarwienia"
            ],
            "ingredients": [
                "Nektar Komórkowy",
                "Olej Z Monoi",
                "Olej Z Pestek Malin",
                "Olej Arganowy",
                "Karoten",
                "Olej Słonecznikowy",
                "Kwas Hialuronowy",
                "Olej Kokosowy",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "Spf50",
                "Komórki Macierzyste",
                "Kwas Hialuronowy",
                "D Panthenol",
                "Karoten"
            ]
        },
        {
            "id": 746,
            "body_parties": [],
            "skin_types": [
                "Zniszczona",
                "Zmęczona",
                "Ziemista",
                "Z Przebarwieniami",
                "Wrażliwa",
                "Trądzikowa",
                "Tłusta",
                "Szara",
                "Sucha",
                "Przesuszona",
                "Odwodniona",
                "Normalna",
                "Naczynkowa",
                "Mieszana",
                "Łuszcząca",
                "Łojotokowa",
                "Dojrzała"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Nasion Lnu",
                "Hydromanil"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Pantenol"
            ]
        },
        {
            "id": 749,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 764,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 765,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Kwas Hialuronowy",
                "Hydromanil",
                "Ekstrakt Z Aloesu",
                "D Panthenol",
                "Olej Słonecznikowy",
                "Ekstrakt Z Pestek Winogron"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Aloes",
                "D Panthenol"
            ]
        },
        {
            "id": 766,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Hydromanil",
                "Olej Słonecznikowy",
                "Ekstrakt Z Pestek Winogron",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Aceroli"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Pantenol",
                "Witamina B"
            ]
        },
        {
            "id": 767,
            "body_parties": [
                "Ciało",
                "Twarz",
                "Włosy"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Hydromanil",
                "Ekstrakt Z Żeń Szenia",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Aloesu",
                "Kwas Hialuronowy",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol",
                "Witamina B"
            ]
        },
        {
            "id": 768,
            "body_parties": [],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Cera Dojrzała",
                "Przebarwienia"
            ],
            "ingredients": [
                "Koloidalna Platyna",
                "Peptyd Miedziowy",
                "Niacynamid"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 769,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Szara",
                "Zmęczona",
                "Z Przebarwieniami",
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Przebarwienia",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Ekstrakt Z Kwiatów Maku"
            ],
            "basic_active_ingredients": [
                "Kwas Kawowy",
                "D Panthenol"
            ]
        },
        {
            "id": 771,
            "body_parties": [
                "Usta"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Olej Migdałowy",
                "Masło Shea",
                "Olej Z Pestek Malin",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 772,
            "body_parties": [
                "Usta"
            ],
            "skin_types": [
                "Sucha",
                "Przesuszona",
                "Odwodniona"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Olej Z Pestek Malin",
                "Olej Słonecznikowy",
                "Masło Shea",
                "Ekstrakt Z Malin"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 773,
            "body_parties": [
                "Usta"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Masło Shea",
                "Olej Słonecznikowy",
                "Ekstrakt Z Malin",
                "Skwalan",
                "Olej Z Pestek Malin",
                "Ekstrakt Z Miodu"
            ],
            "basic_active_ingredients": [
                "Masło Shea",
                "Skwalan"
            ]
        },
        {
            "id": 780,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Z Pestek Malin",
                "Olej Monoi",
                "Nektar Komórkowy",
                "Olej Arganowy",
                "Olej Słonecznikowy",
                "Kwas Hialuronowy",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "Spf50",
                "Karoten",
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        },
        {
            "id": 783,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Ze Złotej Algi",
                "Ekstrakt Z Lotosu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Pantenol"
            ]
        },
        {
            "id": 784,
            "body_parties": [
                "Ciało",
                "Włosy"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Kwas Hialuronowy",
                "Ekstrakt Ze Złotej Algi",
                "Ekstrakt Z Lotosu",
                "D Pantenol"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        },
        {
            "id": 785,
            "body_parties": [
                "Ciało",
                "Dłonie"
            ],
            "skin_types": [
                "Normalna",
                "Sucha",
                "Przesuszona",
                "Odwodniona"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Olej Arganowy",
                "Kwas Hialuronowy",
                "Ekstrakt Ze Złotej Algi",
                "Ekstrakt Z Lotosu"
            ],
            "basic_active_ingredients": [
                "Skwalan"
            ]
        },
        {
            "id": 796,
            "body_parties": [
                "Stopy"
            ],
            "skin_types": [
                "Sucha"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Jodły",
                "Olejek Eteryczny Z Eukaliptusa",
                "Olejek Z Rozmarynu",
                "Ekstrakt Z Lilii Wodnej"
            ],
            "basic_active_ingredients": [
                "Mocznik",
                "Kwasy Aha",
                "Kwasy Bha",
                "Kwas Salicylowy",
                "Kwas Jabłkowy",
                "Kwas Mlekowy",
                "Kwas Winowy",
                "Kwas Cytrynowy"
            ]
        },
        {
            "id": 797,
            "body_parties": [
                "Stopy"
            ],
            "skin_types": [
                "Sucha",
                "Łuszcząca",
                "Normalna"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Olej Migdałowy",
                "Ekstrakt Z Jodły",
                "Mocznik",
                "Kwasy Aha",
                "Kwas Mlekowy",
                "Kwas Winowy",
                "Olejki Eukaliptusowe",
                "Olejek Z Rozmarynu"
            ],
            "basic_active_ingredients": [
                "Mocznik",
                "Kwasy Aha",
                "Kwas Mlekowy",
                "Kwas Winowy"
            ]
        },
        {
            "id": 798,
            "body_parties": [],
            "skin_types": [
                "Sucha",
                "Bardzo Sucha",
                "Szorstka"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Masło Kokum",
                "Olej Migdałowy",
                "Inulina",
                "Mocznik",
                "Ekstrakt Z Jodły",
                "D Pantenol",
                "Ekstrakt Z Lawendy",
                "Olej Kokosowy"
            ],
            "basic_active_ingredients": [
                "Mocznik",
                "Kwas Hialuronowy",
                "Witamina E",
                "D Pantenol"
            ]
        },
        {
            "id": 799,
            "body_parties": [
                "Stopy"
            ],
            "skin_types": [
                "Sucha",
                "Łuszcząca"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Olej Migdałowy",
                "Ekstrakt Z Jodły",
                "Olej Lawendowy",
                "Olej Rycynowy",
                "Wosk Ryżowy",
                "Wosk Karnauba",
                "Wosk Pszczeli",
                "Masło Shea"
            ],
            "basic_active_ingredients": [
                "Witamina A",
                "Witamina E",
                "D Pantenol"
            ]
        },
        {
            "id": 800,
            "body_parties": [
                "Stopy"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Mocznik",
                "Kwas Mlekowy",
                "Ekstrakt Z Jodły"
            ],
            "basic_active_ingredients": [
                "Mocznik",
                "Kwas Mlekowy"
            ]
        },
        {
            "id": 801,
            "body_parties": [
                "Dłonie",
                "Stopy"
            ],
            "skin_types": [
                "Zniszczona"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Konopny",
                "Kolagen",
                "Elastyna",
                "Ceramidy",
                "Witamina A",
                "Witamina E"
            ],
            "basic_active_ingredients": [
                "Kolagen",
                "Elastyna",
                "Witamina A",
                "Witamina E",
                "Ceramidy"
            ]
        },
        {
            "id": 804,
            "body_parties": [
                "Miejsca intymne"
            ],
            "skin_types": [
                "Wrażliwa",
                "Sucha",
                "Odwodniona"
            ],
            "skin_problems": [],
            "ingredients": [
                "Hydrolat Z Neroli",
                "Ekstrakt Z Żurawiny",
                "Ekstrakt Z Aloesu",
                "D Panthenol",
                "Inulina",
                "Kwas Hialuronowy"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 805,
            "body_parties": [
                "Miejsca intymne"
            ],
            "skin_types": [],
            "skin_problems": [
                "Stany zapalne"
            ],
            "ingredients": [
                "Hydrolat Z Róży",
                "Olej Z Jojoba",
                "Olej Z Makadamia",
                "Jonizowane Srebro",
                "Ekstrakt Z Aloesu",
                "D Pantenol",
                "Kwas Mlekowy"
            ],
            "basic_active_ingredients": [
                "D Pantenol",
                "Kwas Mlekowy"
            ]
        },
        {
            "id": 808,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Nasion Lnu"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 809,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Hydromanil"
            ],
            "basic_active_ingredients": [
                "Probiotyki",
                "Kwas Salicylowy",
                "Kwas Askorbinowy",
                "Resplanta"
            ]
        },
        {
            "id": 812,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Problem Przebarwienia"
            ],
            "ingredients": [
                "Kwas Winowy"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwas Askorbinowy"
            ]
        },
        {
            "id": 813,
            "body_parties": [
                "Ciało",
                "Dłonie"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 814,
            "body_parties": [
                "Ciało",
                "Dłonie"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [],
            "basic_active_ingredients": []
        },
        {
            "id": 998,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [],
            "skin_problems": [
                "Przebarwienia"
            ],
            "ingredients": [
                "Niacynamid",
                "Kwas Traneksamowy",
                "Kwas Migdałowy",
                "Kwas Laktobionowy",
                "Ekstrakt Z Lukrecji"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Niacynamid"
            ]
        },
        {
            "id": 999,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [],
            "skin_problems": [
                "Przebarwienia",
                "Fotostarzenie"
            ],
            "ingredients": [
                "Kwas Hialuronowy",
                "Niacynamid",
                "Glutation",
                "α-Arbutyna"
            ],
            "basic_active_ingredients": [
                "Niacynamid",
                "Kwas Hialuronowy",
                "Antyoksydanty"
            ]
        },
        {
            "id": 1000,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Z Przebarwieniami",
                "Dojrzała"
            ],
            "skin_problems": [
                "Przebarwienia"
            ],
            "ingredients": [
                "Hydrolat Z Neroli"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 1001,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Przebarwienia",
                "Trądzik Pospolity",
                "Fotostarzenie"
            ],
            "ingredients": [
                "Olej Słonecznikowy",
                "Masło Shea",
                "Kwas Hialuronowy",
                "Niacynamid",
                "Mocznik"
            ],
            "basic_active_ingredients": [
                "Niacynamid",
                "Kwas Hialuronowy",
                "Kwas Mandelowy",
                "Kwas Laktobionowy",
                "Ubiquinone"
            ]
        },
        {
            "id": 1002,
            "body_parties": [],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Przebarwienia",
                "Cera Ziemista Zmęczona",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Hydromanil",
                "Niacynamid",
                "Kwas Hialuronowy",
                "Ekstrakt Z Lukrecji"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Niacynamid",
                "Skwalan"
            ]
        },
        {
            "id": 1003,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Kwas Hialuronowy",
                "Ekstrakt Z Daktyli",
                "Ekstrakt Z Miodu",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        },
        {
            "id": 1004,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Kwas Hialuronowy",
                "Hydromanil",
                "Ekstrakt Z Truskawek",
                "Ekstrakt Z Malin",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Pantenol"
            ]
        },
        {
            "id": 1005,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Sucha",
                "Przesuszona",
                "Odwodniona",
                "Normalna"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Olej Jojoba",
                "Masło Shea",
                "Ekstrakt Z Truskawek"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "D Pantenol"
            ]
        },
        {
            "id": 1006,
            "body_parties": [],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Słonecznikowy",
                "Masło Shea",
                "Ekstrakt Z Daktyli"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 1007,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Migdałowy",
                "Olej Słonecznikowy",
                "Masło Shea",
                "Ekstrakt Z Migdałów"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 1008,
            "body_parties": [],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Kwas Hialuronowy",
                "Hydromanil",
                "Ekstrakt Z Migdałów",
                "Ekstrakt Z Kakaowca",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Hydromanil",
                "D Panthenol"
            ]
        },
        {
            "id": 1009,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa",
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Przebarwienia"
            ],
            "ingredients": [
                "Ekstrakt Ze Śliwek Kakadu",
                "Ekstrakt Z Wiśni",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Jagód Goji",
                "Olej Z Pestek Śliwek",
                "Ekstrakt Z Nasion Chia",
                "Kwas Hialuronowy",
                "Kolagen",
                "Elastyna"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwasy Aha",
                "Kolagen",
                "Elastyna",
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        },
        {
            "id": 1011,
            "body_parties": [],
            "skin_types": [
                "Wrażliwa",
                "Naczynkowa"
            ],
            "skin_problems": [
                "Cera Wrażliwa Naczynkowa"
            ],
            "ingredients": [
                "Witamina C",
                "Ekstrakt Z Grejpfruta",
                "Ekstrakt Z Miłorzębu",
                "Olej Arganowy",
                "Masło Shea",
                "Proteiny Jedwabiu",
                "Lecytyna",
                "Hydrolat",
                "Algi",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Mimozy"
            ],
            "basic_active_ingredients": [
                "Witamina C",
                "Kwas Hialuronowy",
                "Ceramidy",
                "D Pantenol"
            ]
        },
        {
            "id": 1013,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Słonecznikowy",
                "Olej Arganowy",
                "Olej Kokosowy",
                "Masło Shea",
                "Skwalan z Oliwek",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Owsa",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "Żel Aloesowy",
                "Witamina A",
                "Witamina E",
                "D Pantenol",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 1018,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [
                "Sucha",
                "Przesuszona",
                "Wrażliwa"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Konopny",
                "Masło Shea",
                "Hydromanil",
                "Kwas Hialuronowy",
                "Ekstrakt Z Awokado",
                "Ekstrakt Z Nasion Lnu",
                "Ekstrakt Z Aloesu"
            ],
            "basic_active_ingredients": [
                "D Pantenol",
                "Witamina E",
                "Witamina C"
            ]
        },
        {
            "id": 1028,
            "body_parties": [
                "Ciało",
                "Dłonie"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Pomarańczy",
                "Ekstrakt Z Żurawiny"
            ],
            "basic_active_ingredients": [
                "Masło Shea",
                "Kwas Salicylowy",
                "Kwas Hialuronowy",
                "Witamina C",
                "Witamina E"
            ]
        },
        {
            "id": 1029,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Sucha",
                "Wrażliwa",
                "Normalna"
            ],
            "skin_problems": [
                "Cera Ziemista Zmęczona",
                "Cera Dojrzała",
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Ekstrakt Ze Śliwek Kakadu",
                "Ekstrakt Z Wiśni",
                "Ekstrakt Z Mango",
                "Ekstrakt Z Nasion Chia"
            ],
            "basic_active_ingredients": [
                "Witamina C"
            ]
        },
        {
            "id": 1030,
            "body_parties": [
                "Twarz"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Aktywny Tlen"
            ],
            "basic_active_ingredients": []
        },
        {
            "id": 1034,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Normalna",
                "Zmęczona",
                "Z Przebarwieniami",
                "Wrażliwa",
                "Sucha",
                "Odwodniona",
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Przebarwienia",
                "Cera Wrażliwa Naczynkowa",
                "Cera Sucha Odwodniona",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Woda Różana",
                "Kwas Hialuronowy",
                "Ekstrakt Z Żyworódki",
                "Peptydy Biomimetyczne"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Peptydy"
            ]
        },
        {
            "id": 1035,
            "body_parties": [],
            "skin_types": [],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności"
            ],
            "ingredients": [
                "Woda Różana",
                "Olej Z Awokado",
                "Masło Shea",
                "Olej Z Meadowfoam",
                "Koenzym Q10"
            ],
            "basic_active_ingredients": [
                "Peptydy",
                "Witamina E",
                "Koenzym Q10"
            ]
        },
        {
            "id": 1036,
            "body_parties": [
                "Oczy"
            ],
            "skin_types": [],
            "skin_problems": [
                "Zmarszczki"
            ],
            "ingredients": [
                "Woda Różana",
                "Olej Z Awokado",
                "Kwas Hialuronowy",
                "Ekstrakt Z Pięknotki Japońskiej",
                "Ekstrakt Z Żyworódki",
                "Olej Z Meadowfoam",
                "Witamina E"
            ],
            "basic_active_ingredients": [
                "Peptydy Biomimetyczne",
                "Kwas Hialuronowy",
                "Witamina E"
            ]
        },
        {
            "id": 1037,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Problem Zmarszczki"
            ],
            "ingredients": [
                "Olej Z Meadowfoam",
                "Ekstrakt Z Pięknotki Japońskiej",
                "Ekstrakt Z Żyworódki",
                "Olej Z Awokado"
            ],
            "basic_active_ingredients": [
                "Peptydy Biomimetyczne",
                "Witamina E",
                "Witamina C",
                "Witamina A"
            ]
        },
        {
            "id": 1041,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [
                "Przesuszona"
            ],
            "skin_problems": [],
            "ingredients": [
                "Olej Migdałowy",
                "Olej Jojoba",
                "Ekstrakt Z Daktyli",
                "Ekstrakt Z Kiełków Pszenicy",
                "Witamina E"
            ],
            "basic_active_ingredients": [
                "Witamina E"
            ]
        },
        {
            "id": 1042,
            "body_parties": [
                "Dłonie"
            ],
            "skin_types": [],
            "skin_problems": [],
            "ingredients": [
                "Kwas Hialuronowy",
                "Masło Shea",
                "Olej Migdałowy",
                "Olej Arganowy",
                "Ekstrakt Z Orchidei",
                "Ekstrakt Z Lotosu",
                "Ekstrakt Z Nasion Lnu"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Masło Shea",
                "Witamina E",
                "D Pantenol",
                "Witamina C"
            ]
        },
        {
            "id": 1043,
            "body_parties": [
                "Ciało",
                "Włosy"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Ekstrakt Z Lotosu",
                "Ekstrakt Z Figi"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        },
        {
            "id": 1048,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Ekstrakt Z Czerwonej Algi",
                "Egzopolisacharydy Morskie"
            ],
            "basic_active_ingredients": [
                "Komórki Macierzyste",
                "Kolagen",
                "Elastyna"
            ]
        },
        {
            "id": 1049,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Utrata Jędrności",
                "Zmarszczki"
            ],
            "ingredients": [],
            "basic_active_ingredients": [
                "Komórki Macierzyste",
                "Kwas Hialuronowy"
            ]
        },
        {
            "id": 1050,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Kwas Hialuronowy",
                "Fitokolagen",
                "Olej Bakuchi",
                "Masło Shea",
                "Skwalan",
                "Egzopolisachardy Morskie",
                "Komórki Macierzyste"
            ],
            "basic_active_ingredients": [
                "Komórki Macierzyste",
                "Kwas Hialuronowy",
                "Fitokolagen",
                "Skwalan",
                "Masło Shea",
                "Olej Bakuchi"
            ]
        },
        {
            "id": 1051,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Sucha Odwodniona",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Kwas Hialuronowy",
                "Fitokolagen",
                "Olej Bakuchi",
                "Skwalan",
                "Olej Słonecznikowy"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Bakuchiol",
                "Skwalan"
            ]
        },
        {
            "id": 1052,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Olej Migdałowy",
                "Komórki Macierzyste"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Witamina E",
                "Witamina C",
                "Kolagen"
            ]
        },
        {
            "id": 1053,
            "body_parties": [
                "Twarz",
                "Szyja/Dekolt"
            ],
            "skin_types": [
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Dojrzała"
            ],
            "ingredients": [
                "Kwas Hialuronowy",
                "Fitokolagen",
                "Ekstrakt Z Czerwonej Algi"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Fitokolagen"
            ]
        },
        {
            "id": 1054,
            "body_parties": [
                "Ciało",
                "Włosy"
            ],
            "skin_types": [
                "Zmęczona",
                "Dojrzała"
            ],
            "skin_problems": [
                "Zmarszczki",
                "Utrata Jędrności",
                "Cera Ziemista Zmęczona"
            ],
            "ingredients": [
                "Kwas Hialuronowy",
                "Ekstrakt Z Granatu",
                "Flawonoidy",
                "Witamina C",
                "Ekstrakt Z Pomarańczy",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "Witamina C",
                "D Panthenol"
            ]
        },
        {
            "id": 1055,
            "body_parties": [
                "Ciało",
                "Dłonie"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [
                "Cera Sucha Odwodniona"
            ],
            "ingredients": [
                "Olej Arganowy",
                "Olej Słonecznikowy",
                "Kwas Hialuronowy",
                "Ekstrakt Z Mandarynki",
                "Ekstrakt Z Aloesu",
                "Ekstrakt Z Pomarańczy",
                "Ekstrakt Z Fig",
                "Ekstrakt Z Granatu"
            ],
            "basic_active_ingredients": [
                "Witamina E",
                "Witamina C",
                "Kwas Hialuronowy",
                "Aloes"
            ]
        },
        {
            "id": 1056,
            "body_parties": [
                "Ciało"
            ],
            "skin_types": [
                "Normalna"
            ],
            "skin_problems": [],
            "ingredients": [
                "Kwas Hialuronowy",
                "Ekstrakt Z Pomarańczy",
                "Ekstrakt Z Granatu",
                "Hydromanil",
                "D Panthenol"
            ],
            "basic_active_ingredients": [
                "Kwas Hialuronowy",
                "D Panthenol"
            ]
        }
    ]
};