<template>
  <div class="box-card">
    <el-select @change="updateSelectd" v-model="selectedIngredients" multiple placeholder="Select active ingredients" style="width: 100%">
      <el-option v-for="(ingredient, index) in ingredients" :key="index" :label="ingredient" :value="ingredient">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { activeIngredients } from './data/activeIngredients.js';
export default {
  name: 'ActiveIngredientsList',
  data() {
    return {
      ingredients: activeIngredients,
      selectedIngredients: []
    }
  },
  methods:{
    updateSelectd() {
      this.$emit('update', this.selectedIngredients);
    }

  }  
}
</script>

<style scoped>
.box-card {
  width: 400px;
  margin: 20px;
}
</style>